import React, { useMemo } from "react";
import { Card } from "react-bootstrap";
import getBillingProductsColumns from "../../inputData/billingProductsData";
import { Container, Row, Col, Table, Pagination, Form } from "react-bootstrap";
import {
  defaultColumn,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const RowSelectingTable = ({ columns: offerColumns, data }) => {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: offerColumns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );
  return (
    <Card>
      <Card.Body>
        <Table id="billingProductsTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span>
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortUp className="ms-2 " />
                            ) : (
                              <FaSortDown className="ms-2 " />
                            )
                          ) : (
                            <FaSort className="ms-2" />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {isDesktop && (
          <Row>
            <Col md="8">
              <span className="mx-2">
                {t("table.page")}{" "}
                <strong>
                  {pageIndex + 1} {t("table.of")} {pageOptions.length}
                </strong>
              </span>
              <span className="ms-3 me-2">{t("table.show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">{t("table.goToPage")}:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="4">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => {
                    gotoPage(0);
                  }}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => {
                    previousPage();
                  }}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => {
                    nextPage();
                  }}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => {
                    gotoPage(pageCount - 1);
                  }}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        )}
        {!isDesktop && (
          <div className="billing-products-pagination mt-3">
            <Row className="mb-3">
              <Col className="d-flex justify-content-center">
                <Pagination className="float-end">
                  <Pagination.Prev
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <Col className="d-flex align-items-end mx-2 px-3">
                    <span className="mx-2">
                      <strong>
                        {pageIndex + 1} {t("table.of")} {pageOptions.length}
                      </strong>
                    </span>
                  </Col>
                  <Pagination.Next
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                </Pagination>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs="auto">
                <span className="me-2">{t("table.show")}:</span>
                <Form.Select
                  className="d-inline-block w-auto"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  style={{
                    padding: "0.25rem 2.1rem 0.25rem 0.7rem",
                    border: "none",
                    boxShadow: "none",
                    fontSize: "14px",
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <div className="float-end">
                  <span className="me-2">{t("table.goToPage")}:</span>
                  <Form.Control
                    className="d-inline-block w-auto"
                    type="number"
                    defaultValue={pageIndex + 1}
                    min={1}
                    max={pageCount}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{
                      padding: "0.25rem 1.2rem 0.25rem 0.7rem",
                      fontSize: "0.825rem",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const RowExpanding = (props) => {
  const { t } = useTranslation();
  const { billingProductsColumns } = useMemo(
    () => getBillingProductsColumns(t),
    [t]
  );
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <RowSelectingTable columns={billingProductsColumns} data={props.data} />
      </Container>
    </React.Fragment>
  );
};

const BillingProductsList = (props) => {
  return (
    <Card>
      <Card.Body>
        <RowExpanding data={props.data} />
      </Card.Body>
    </Card>
  );
};

export default BillingProductsList;
