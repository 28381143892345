import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SimFiltersContext = createContext();

export const SimFiltersProvider = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState(user);
  const location = useLocation();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);
  const initialFilters = {
    imsi: "",
    iccid: "",
    msisdn: "",
    state: "",
    eid: "",
    minData: "",
    maxData: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const [sort, setSort] = useState({
    sortField: "imsi",
    sortOrder: "asc",
  });

  useEffect(() => {
    // Reset filters every time activeUser.id changes
    setFilters(initialFilters);
  }, [activeUser.id]);

  useEffect(() => {
    // Retain filters only between /sims and /sims/profile/{imsi}
    const isProfilePage = location.pathname.startsWith("/sims/profile/");
    const isSimsPage = location.pathname === "/sims";

    if (!isSimsPage && !isProfilePage) {
      setFilters(initialFilters); // Reset filters if not on /sims or /sims/profile/{imsi}
    }
  }, [location.pathname]);

  return (
    <SimFiltersContext.Provider value={{ filters, setFilters, sort, setSort }}>
      {children}
    </SimFiltersContext.Provider>
  );
};

export default SimFiltersContext;
