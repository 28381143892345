import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaHome, FaSimCard, FaUser, FaFile, FaEllipsisH } from "react-icons/fa";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import reduceChildRoutes from "./reduceChildRoutes";
import SidebarNav from "./SidebarNav";
import { useSelector } from "react-redux";

const BottomNavbar = ({ items }) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => setShowMore(true);
  const handleCloseMore = () => setShowMore(false);
  const { backgroundColor, fontColor } = useWhitelabel();
  const router = useLocation();
  const currentRoute = router.pathname;
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  return (
    <>
      <div className="bottom-navbar d-flex justify-content-around bg-light">
        <NavLink
          to="/profile"
          className="nav-item text-center"
          style={{
            "--dynamic-bg-color": backgroundColor,
            "--dynamic-font-color": fontColor,
          }}
        >
          <FaUser size={24} />
          <p>Profile</p>
        </NavLink>
        <NavLink
          to="/reports/single"
          className="nav-item text-center"
          style={{
            "--dynamic-bg-color": backgroundColor,
            "--dynamic-font-color": fontColor,
          }}
        >
          <FaFile size={24} />
          <p>Reports</p>
        </NavLink>
        <NavLink
          to="/"
          className="nav-item text-center"
          style={{
            "--dynamic-bg-color": backgroundColor,
            "--dynamic-font-color": fontColor,
          }}
        >
          <FaHome size={24} />
          <p>Dashboard</p>
        </NavLink>
        <NavLink
          to="/sims"
          className="nav-item text-center"
          style={{
            "--dynamic-bg-color": backgroundColor,
            "--dynamic-font-color": fontColor,
          }}
        >
          <FaSimCard size={24} />
          <p>SIMs</p>
        </NavLink>
        <div className="nav-item text-center" onClick={handleShowMore}>
          <FaEllipsisH size={24} />
          <p>More</p>
        </div>
      </div>

      {/* Modal for More Menu */}
      <Modal
        placement="end"
        show={showMore}
        fullscreen
        // style={{
        //   width: "100%",
        // }}
        onHide={handleCloseMore}
        // size="lg"
        // centered
      >
        <Modal.Body
          style={{
            backgroundColor: backgroundColor,
            color: fontColor,
          }}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: backgroundColor,
              color: fontColor,
              borderBottom: "none",
            }}
          />
          <ul className="sidebar-nav">
            {items &&
              items.map((item, index) => {
                const reducedItems = item.pages.reduce(
                  (items, page) =>
                    reduceChildRoutes({
                      items,
                      page,
                      depth: 0,
                      currentRoute,
                      privilege: activeUser.role,
                      activeUser,
                      handleCloseMore,
                    }),
                  []
                );
                return (
                  <React.Fragment>
                    {item.title && (
                      <li className="sidebar-header">{item.title}</li>
                    )}
                    <React.Fragment>{reducedItems}</React.Fragment>
                  </React.Fragment>
                );
              })}
          </ul>
          {/* <div className="d-flex justify-content-center mt-3">
            <Button
              variant="secondary"
              onClick={handleCloseMore}
              style={{
                width: "100%",
                padding: "10px",
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
              }}
            >
              Close
            </Button>
          </div> */}
        </Modal.Body>
        {/* <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="secondary"
            onClick={handleCloseMore}
            style={{
              width: "100%",
              padding: "10px",
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
            }}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default BottomNavbar;
