import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { IoPersonAddSharp } from "react-icons/io5";
import ClientPagination from "./ClientPagination";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";

const DataCard = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  setShownClient,
  setOpen,
  setSingleIsShown,
  pageIndex,
  pageSize,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  t,
  title,
  showCreateEntityModal,
  setShowCreateEntityModal,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  return (
    <Card>
      <Card.Body>
        {isDesktop && (
          <Row className="g-0">
            <Col sm="12" xl="12" className="col-xxl-12">
              <div className="d-flex mt-2 float-end">
                {activeUser.role !== "subaccount" && title !== "Admins" ? (
                  <Button
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                    onClick={() => setShowCreateEntityModal(true)}
                  >
                    <IoPersonAddSharp />{" "}
                    {title === "Tenants"
                      ? t("crm.newTenant")
                      : title === "Managers"
                      ? t("crm.newManager")
                      : title === "Customers"
                      ? t("crm.newCustomer")
                      : title === "Subaccounts"
                      ? t("crm.newSubaccount")
                      : null}
                  </Button>
                ) : null}
              </div>
            </Col>
          </Row>
        )}
        {!isDesktop && activeUser.role !== "subaccount" && title !== "Admins" && (
          <div className="d-flex justify-content-center mb-3">
            <Button
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
                width: "100%",
                padding: "10px",
              }}
              onClick={() => setShowCreateEntityModal(true)}
            >
              <IoPersonAddSharp />{" "}
              {title === "Tenants"
                ? t("crm.newTenant")
                : title === "Managers"
                ? t("crm.newManager")
                : title === "Customers"
                ? t("crm.newCustomer")
                : title === "Subaccounts"
                ? t("crm.newSubaccount")
                : null}
            </Button>
          </div>
        )}
        <Table id="clientsTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span>
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortUp className="ms-2 " />
                            ) : (
                              <FaSortDown className="ms-2 " />
                            )
                          ) : (
                            <FaSort className="ms-2" />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    setShownClient(row.original);
                    setOpen(true);
                    setTimeout(function () {
                      setSingleIsShown(true);
                    }, 500);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ClientPagination
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          t={t}
        />
      </Card.Body>
    </Card>
  );
};

export default DataCard;
