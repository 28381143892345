import React from "react";
import { Card, Form, Row } from "react-bootstrap";
import FilterGroup from "./FilterGroup";
import { useTranslation } from "react-i18next";
import useWindowSize from "../hooks/useWindowSize";

const FiltersCard = ({ headerGroups }) => {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("crm.filters")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          {isDesktop && (
            <Row>
              {headerGroups.map((headerGroup, idx) =>
                headerGroup.headers.map((column, index) => (
                  <FilterGroup key={index} column={column} />
                ))
              )}
            </Row>
          )}
          {!isDesktop && (
            <>
              {headerGroups.map((headerGroup, idx) =>
                headerGroup.headers.map((column, index) => (
                  <FilterGroup key={index} column={column} />
                ))
              )}
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FiltersCard;
