import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";
import Header from "./Header";
import Statistics from "./statistics";
import CountryDistribution from "./WorldMap";
import DataVolumeBarChart from "./DataVolumeBarChart";
import TopMetricsBarChart from "./TopMetricsBarChart";
import WatchdogAlerts from "./WatchdogTable";
import NotyfContext from "../../contexts/NotyfContext";
import SimInventoryPieChart from "./SimInventoryPie";
import OffersDistributionBar from "./OffersDistributionPie";
import NetworksDistributionPieChart from "./NetworksDistributionPieChart";
import DataVolumeNetworkPieChart from "./DataVolumeNetworPieChart";
import {
  getActiveSims,
  getAlertCounts,
  getAverageDataVolume,
  getCounts,
  getDataSessionCounts,
  getDataVolumeCounts,
  getZeroSessionCounts,
} from "../../redux/slices/dashboard";
import DataVolumePerHourChart from "./DataVolumePerHourChart";
import Loader from "../spinners/Loader";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import AdditionalStatistics from "./additionalStatistics";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import useWindowSize from "../../hooks/useWindowSize";

const Dashboard = () => {
  const [type, setType] = useState("day");
  const [reload, setReload] = useState(false);
  const { faviconUrl } = useWhitelabel();
  const [width] = useWindowSize();
  const isMobile = width <= 480; // Covers mobile devices
  const isTablet = width > 480 && width <= 768; // Covers tablets
  const isDesktop = width > 768; // Covers desktops

  if (isDesktop) {
    return (
      <React.Fragment>
        <Helmet title="Analytics Dashboard">
          <link rel="icon" href={faviconUrl} />
        </Helmet>
        <Container fluid className="p-0">
          <Header type={type} setType={setType} setReload={setReload} />

          <Row>
            <Col md="12" lg="12" xl="5" className="d-flex">
              <Statistics type={type} reload={reload} />
            </Col>
            <Col md="12" lg="12" xl="7" className="d-flex">
              <CountryDistribution period={type} reload={reload} />
            </Col>
          </Row>
          <AdditionalStatistics type={type} reload={reload} />
          <Row>
            <Col className="d-flex">
              <DataVolumePerHourChart reload={reload} />
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" xl="8" className="d-flex">
              <DataVolumeBarChart period={type} reload={reload} />
            </Col>
            <Col md="12" lg="12" xl="4" className="d-flex">
              <TopMetricsBarChart period={type} reload={reload} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" xl xxl="6" className="d-flex">
              <OffersDistributionBar reload={reload} />
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl xxl="6" className="d-flex">
              <SimInventoryPieChart reload={reload} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" xl xxl="6" className="d-flex">
              <DataVolumeNetworkPieChart reload={reload} />
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl xxl="6" className="d-flex">
              <NetworksDistributionPieChart reload={reload} />
            </Col>
          </Row>
          <Row>
            <Col>
              <WatchdogAlerts reload={reload} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet title="Analytics Dashboard">
          <link rel="icon" href={faviconUrl} />
        </Helmet>
        <Container fluid className="px-0 pt-0 pb-5">
          <Header type={type} setType={setType} setReload={setReload} />
          <Statistics type={type} reload={reload} />
          <CountryDistribution period={type} reload={reload} />
          <AdditionalStatistics type={type} reload={reload} />
          <DataVolumePerHourChart reload={reload} />
          <DataVolumeBarChart period={type} reload={reload} />
          <TopMetricsBarChart period={type} reload={reload} />
          <OffersDistributionBar reload={reload} />
          <SimInventoryPieChart reload={reload} />
          <DataVolumeNetworkPieChart reload={reload} />
          <NetworksDistributionPieChart reload={reload} />
          <WatchdogAlerts reload={reload} />
        </Container>
      </React.Fragment>
    );
  }
};

export default Dashboard;
