import { Formik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import {
  getBillingPreferences,
  updateBillingPreferences,
} from "../../../redux/slices/preferences";
import * as Yup from "yup";
import CurrencyList from "currency-list";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";

const Billing = ({ activeTab }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(false);
  const billingPreferences = useSelector(
    (state) => state.preferences.billingPreferences
  );
  const currencyList = CurrencyList.getAll("en_US");
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchBillingPreferences = async () => {
      setIsLoading(true);
      await dispatch(getBillingPreferences())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            ripple: true,
            dismissible: false,
            duration: 5000,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (
      activeUser.id &&
      activeUser.role !== "admin" &&
      activeUser.role !== "subaccount" &&
      activeTab === "#Billing"
    ) {
      fetchBillingPreferences();
    }
  }, [activeUser, activeTab]);

  const initialValues = {
    // billingEmail: billingPreferences?.billingEmail ?? "",
    currency: billingPreferences?.currency ?? "",
    preferred_language: billingPreferences?.preferredLanguage ?? "",
  };

  const validation = Yup.object().shape({
    // billingEmail: Yup.string().email().required("Required"),
    currency: Yup.string().required("Required"),
    preferred_language: Yup.string().required("Required"),
  });

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("settings.billingSettings.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              const data = {
                currency: values.currency,
                preferredLanguage: values.preferred_language,
              };
              await dispatch(updateBillingPreferences({ data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Billing preferences updated successfully",
                    ripple: true,
                    dismissible: false,
                    duration: 5000,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    ripple: true,
                    dismissible: false,
                    duration: 5000,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.billingSettings.currency")}
                    </Form.Label>
                    <Form.Select
                      name="currency"
                      value={values.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.currency && errors.currency}
                    >
                      <option value="">
                        {t("settings.billingSettings.selectCurrency")}
                      </option>
                      {Object.keys(currencyList).map((currencyCode) => {
                        const currency = currencyList[currencyCode];
                        return (
                          <option key={currencyCode} value={currencyCode}>
                            {currencyCode} - {currency.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.billingSettings.preferredLanguage")}
                    </Form.Label>
                    <Form.Select
                      name="preferred_language"
                      value={values.preferred_language}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.preferred_language && errors.preferred_language
                      }
                    >
                      <option value="">
                        {t("settings.billingSettings.selectLanguage")}
                      </option>
                      <option value="English">English</option>
                    </Form.Select>
                    {!!touched.preferred_language && (
                      <Form.Control.Feedback type="invalid">
                        {errors.preferred_language}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  {/* <Form.Group className="mb-3">
                  <Form.Label>Billing Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="billingEmail"
                    value={values.billingEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.billingEmail && errors.billingEmail}
                  />
                  {!!touched.billingEmail && (
                    <Form.Control.Feedback type="invalid">
                      {errors.billingEmail}
                    </Form.Control.Feedback>
                  )}
                </Form.Group> */}
                  {isDesktop && (
                    <Row>
                      <Col md="auto" className="ms-auto">
                        <Button
                          type="submit"
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("settings.billingSettings.submit")}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {!isDesktop && (
                    <div className="mt-3 d-flex justify-content-center">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        {t("settings.billingSettings.submit")}
                      </Button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default Billing;
