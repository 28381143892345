import React from "react";
import { useTranslation } from "react-i18next";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { usePagination, useSortBy, useTable, defaultColumn } from "react-table";
import { Table, Pagination, Row, Col, Form } from "react-bootstrap";
import useWindowSize from "../../hooks/useWindowSize";

const StatementsTable = ({ columns: statementsColumns, data }) => {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: statementsColumns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Table id="statementsTable" hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <span>
                      {column.sortable ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaSortUp className="ms-2" />
                          ) : (
                            <FaSortDown className="ms-2" />
                          )
                        ) : (
                          <FaSort className="ms-2" />
                        )
                      ) : null}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {isDesktop && (
        <Row>
          <Col md="8">
            <span className="mx-2">
              {t("table.page")}{" "}
              <strong>
                {pageIndex + 1} {t("table.of")} {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">{t("table.show")}:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">{t("table.goToPage")}:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="4">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => {
                  gotoPage(0);
                }}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => {
                  previousPage();
                }}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => {
                  nextPage();
                }}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => {
                  gotoPage(pageCount - 1);
                }}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      )}
      {!isDesktop && (
        <div className="statements-pagination mt-3">
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Pagination className="float-end">
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Col className="d-flex align-items-end mx-2 px-3">
                  <span className="mx-2">
                    <strong>
                      {pageIndex + 1} {t("table.of")} {pageOptions.length}
                    </strong>
                  </span>
                </Col>
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs="auto">
              <span className="me-2">{t("table.show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{
                  padding: "0.25rem 2.1rem 0.25rem 0.7rem",
                  border: "none",
                  boxShadow: "none",
                  fontSize: "14px",
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <div className="float-end">
                <span className="me-2">{t("table.goToPage")}:</span>
                <Form.Control
                  className="d-inline-block w-auto"
                  type="number"
                  defaultValue={pageIndex + 1}
                  min={1}
                  max={pageCount}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{
                    padding: "0.25rem 1.2rem 0.25rem 0.7rem",
                    fontSize: "0.825rem",
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default StatementsTable;
