import React, { useState, useEffect, useContext } from "react";
import {
  Collapse,
  Form,
  Modal,
  Button,
  Spinner,
  Offcanvas,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTenants } from "../../../redux/slices/tenants";
import NotyfContext from "../../../contexts/NotyfContext";
import { updateUserRole } from "../../../redux/slices/user";
import { Formik } from "formik";
import { getManagers } from "../../../redux/slices/managers";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";

const UpdatePrivilegeModal = ({
  showPrivilegeModal,
  setShowPrivilegeModal,
  entityUser,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const tenants = useSelector((state) => state.tenants.tenants);
  const managers = useSelector((state) => state.managers.managers);
  const dispatch = useDispatch();
  const [roleOptions, setRoleOptions] = useState([]);
  const [upperEntityOptions, setUpperEntityOptions] = useState([]);
  const notyf = useContext(NotyfContext);
  const [newRole, setNewRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(showPrivilegeModal);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (showPrivilegeModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showPrivilegeModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShowPrivilegeModal(false);
    }, 300);
  };

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const getAvailableRoles = ({ activeUser, entityUser }) => {
    if (activeUser.role === "admin") {
      if (entityUser.privilege === "TENANT") {
        return [
          { value: "tenant", label: "Tenant" },
          { value: "manager", label: "Manager" },
          { value: "customer", label: "Customer" },
        ];
      } else if (entityUser.privilege === "MANAGER") {
        return [
          { value: "tenant", label: "Tenant" },
          { value: "manager", label: "Manager" },
          { value: "customer", label: "Customer" },
        ];
      } else if (entityUser.privilege === "CUSTOMER") {
        return [
          { value: "tenant", label: "Tenant" },
          { value: "manager", label: "Manager" },
          { value: "customer", label: "Customer" },
        ];
      }
    } else if (activeUser.role === "tenant") {
      if (entityUser.privilege === "MANAGER") {
        return [
          { value: "manager", label: "Manager" },
          { value: "customer", label: "Customer" },
        ];
      } else if (entityUser.privilege === "CUSTOMER") {
        return [
          { value: "manager", label: "Manager" },
          { value: "customer", label: "Customer" },
        ];
      }
    }
    return [];
  };

  useEffect(() => {
    if (activeUser.id) {
      const roles = getAvailableRoles({ activeUser, entityUser });
      setRoleOptions(roles);
      setIsLoading(false);
    }
  }, [activeUser, entityUser]);

  const initialValues = {
    newRole:
      entityUser?.privilege === "TENANT"
        ? "tenant"
        : entityUser?.privilege === "MANAGER"
        ? "manager"
        : "customer",
  };

  const validation = Yup.object().shape({
    newRole: Yup.string().required("Required"),
  });

  if (isDesktop) {
    return (
      <Modal
        show={showPrivilegeModal}
        onHide={() => setShowPrivilegeModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("crm.roleModal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values) => {
              if (values.newRole === entityUser.privilege.toLowerCase()) {
                setShowPrivilegeModal(false);
                return;
              }
              const data = {
                role: values.newRole,
              };

              await dispatch(updateUserRole({ entityUser, data: data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Role updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "error",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowPrivilegeModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              if (isLoading) {
                return (
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="me-2"
                    />
                  </div>
                );
              } else {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("crm.roleModal.role")}</Form.Label>
                      <Form.Select
                        name="newRole"
                        value={values.newRole}
                        isInvalid={Boolean(touched.newRole && errors.newRole)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setNewRole(e.target.value);
                        }}
                      >
                        {roleOptions.map((role) => (
                          <option key={role.value} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                        onClick={() => {
                          setShowPrivilegeModal(false);
                        }}
                      >
                        {t("crm.roleModal.cancel")}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("crm.roleModal.submit")}
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        backdrop="static"
        scrollable={true}
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>{t("crm.roleModal.title")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values) => {
              if (values.newRole === entityUser.privilege.toLowerCase()) {
                setShowPrivilegeModal(false);
                return;
              }
              const data = {
                role: values.newRole,
              };

              await dispatch(updateUserRole({ entityUser, data: data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Role updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "error",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowPrivilegeModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              if (isLoading) {
                return (
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="me-2"
                    />
                  </div>
                );
              } else {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("crm.roleModal.role")}</Form.Label>
                      <Form.Select
                        name="newRole"
                        value={values.newRole}
                        isInvalid={Boolean(touched.newRole && errors.newRole)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setNewRole(e.target.value);
                        }}
                      >
                        {roleOptions.map((role) => (
                          <option key={role.value} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <div className="d-flex flex-column justify-content-center mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                          padding: "10px",
                        }}
                        className="mb-3"
                      >
                        {t("crm.roleModal.submit")}
                      </Button>
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                          padding: "10px",
                        }}
                        onClick={handleHide}
                      >
                        {t("crm.roleModal.cancel")}
                      </Button>
                    </div>
                  </Form>
                );
              }
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default UpdatePrivilegeModal;
