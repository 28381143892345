import React from "react";
import { Row, Col, Pagination, Form } from "react-bootstrap";
import useWindowSize from "../../hooks/useWindowSize";

const ClientPagination = ({
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  pageCount,
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  t,
}) => {
  const [width] = useWindowSize();
  const isDesktop = width > 768; // Covers desktops

  if (isDesktop) {
    return (
      <Row className="client-pagination-row">
        <Col md="9" className="client-pagination-col">
          <span className="mx-2">
            {t("table.page")}{" "}
            <strong>
              {pageIndex + 1} {t("table.of")} {pageOptions.length}
            </strong>
          </span>
          <span className="ms-3 me-2">{t("table.show")}:</span>
          <Form.Select
            className="d-inline-block w-auto"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>

          <span className="ms-3 me-2">{t("table.goToPage")}:</span>
          <Form.Control
            className="d-inline-block"
            type="number"
            defaultValue={pageIndex + 1}
            min={1}
            max={pageCount}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "75px" }}
          />
        </Col>
        <Col md="3" className="client-pagination-col">
          <Pagination className="float-end">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    );
  } else {
    return (
      <React.Fragment>
        <div className="client-pagination mt-3">
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Pagination className="float-end">
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Col className="d-flex align-items-end mx-2 px-3">
                  <span className="mx-2">
                    <strong>
                      {pageIndex + 1} {t("table.of")} {pageOptions.length}
                    </strong>
                  </span>
                </Col>
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs="auto">
              <span className="me-2">{t("table.show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{
                  padding: "0.25rem 2.1rem 0.25rem 0.7rem",
                  border: "none",
                  boxShadow: "none",
                  fontSize: "14px",
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <div className="float-end">
                <span className="me-2">{t("table.goToPage")}:</span>
                <Form.Control
                  className="d-inline-block w-auto"
                  type="number"
                  defaultValue={pageIndex + 1}
                  min={1}
                  max={pageCount}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{
                    padding: "0.25rem 1.2rem 0.25rem 0.7rem",
                    fontSize: "0.825rem",
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
};

export default ClientPagination;
