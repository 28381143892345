import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";

import { RefreshCw } from "react-feather";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";

const Header = ({ type, setType, setReload }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [width] = useWindowSize();
  const isMobile = width <= 480; // Covers mobile devices
  const isTablet = width > 480 && width <= 768; // Covers tablets
  const isDesktop = width > 768; // Covers desktops

  if (isDesktop) {
    return (
      <>
        <Row className="mb-2 mb-xl-3">
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <ButtonGroup className="dashboard-button-group">
              <Button
                key={1}
                onClick={() => setType("day")}
                className={"day" === type ? "active" : null}
                style={{
                  "--dynamic-bg-color":
                    whitelabel?.backgroundColor ?? "#00acdc",
                  "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                  border: "none",
                }}
              >
                {t("dashboard.daily")}
              </Button>
              <Button
                key={2}
                onClick={() => setType("month")}
                className={"month" === type ? "active" : null}
                style={{
                  "--dynamic-bg-color":
                    whitelabel?.backgroundColor ?? "#00acdc",
                  "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                  border: "none",
                }}
              >
                {t("dashboard.monthly")}
              </Button>
              <Button
                key={3}
                onClick={() => setType("year")}
                className={"year" === type ? "active" : null}
                style={{
                  "--dynamic-bg-color":
                    whitelabel?.backgroundColor ?? "#00acdc",
                  "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                  border: "none",
                }}
              >
                {t("dashboard.yearly")}
              </Button>
            </ButtonGroup>
            <Button
              className="shadow-sm border border-0 reload-button"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
              onClick={() => setReload((prev) => !prev)}
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
      </>
    );
  } else if (isTablet) {
    return (
      <Row className="mb-3">
        <Col xs="auto" className="ms-auto text-end mt-n1">
          <ButtonGroup className="dashboard-button-group">
            <Button
              key={1}
              onClick={() => setType("day")}
              className={"day" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              {t("dashboard.daily")}
            </Button>
            <Button
              key={2}
              onClick={() => setType("month")}
              className={"month" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              {t("dashboard.monthly")}
            </Button>
            <Button
              key={3}
              onClick={() => setType("year")}
              className={"year" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              {t("dashboard.yearly")}
            </Button>
          </ButtonGroup>
          <Button
            className="shadow-sm border border-0 reload-button"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
            onClick={() => setReload((prev) => !prev)}
          >
            <RefreshCw className="feather" />
          </Button>
        </Col>
      </Row>
    );
  } else if (isMobile) {
    return (
      <Container
        className="mb-3 d-flex justify-content-center"
        style={{
          overflowX: "auto",
        }}
      >
        <ButtonGroup className="dashboard-button-group">
          <Button
            key={1}
            onClick={() => setType("day")}
            className={"day" === type ? "active" : null}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              padding: "8px 16px",
            }}
          >
            {t("dashboard.daily")}
          </Button>
          <Button
            key={2}
            onClick={() => setType("month")}
            className={"month" === type ? "active" : null}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              padding: "8px 16px",
            }}
          >
            {t("dashboard.monthly")}
          </Button>
          <Button
            key={3}
            onClick={() => setType("year")}
            className={"year" === type ? "active" : null}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              padding: "8px 16px",
            }}
          >
            {t("dashboard.yearly")}
          </Button>
        </ButtonGroup>
        <Button
          className="shadow-sm border border-0 reload-button"
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
            padding: "8px 16px",
          }}
          onClick={() => setReload((prev) => !prev)}
        >
          <RefreshCw className="feather" />
        </Button>
      </Container>
    );
  }
};

export default Header;
