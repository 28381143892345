import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import useWindowSize from "../hooks/useWindowSize";

function DefaultColumnFilterInput({
  column: { filterValue, preFilteredRows, setFilter },
  filterIsShown,
  setFilterIsShown,
}) {
  const filterRef = useRef(null);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  function useOutsideAlerter(ref, setFilterIsShown) {
    useEffect(() => {
      function handleClickOutside(event, setFilterIsShown) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFilterIsShown(false);
        }
      }

      document.addEventListener("click", function (event) {
        handleClickOutside(event, setFilterIsShown);
      });
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref, setFilterIsShown]);
  }

  useOutsideAlerter(filterRef, setFilterIsShown);

  return (
    <>
      {isDesktop && (
        <Form.Group className="mt-1">
          <Form.Control
            value={filterValue || ""}
            onChange={(e) => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            className="w-75"
            placeholder="Enter value..."
          />
        </Form.Group>
      )}
      {!isDesktop && (
        <Form.Group>
          <Form.Control
            value={filterValue || ""}
            onChange={(e) => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder="Enter value..."
          />
        </Form.Group>
      )}
    </>
  );
}

export default function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const [filterIsShown, setFilterIsShown] = useState(false);

  return (
    <DefaultColumnFilterInput
      column={{ filterValue, preFilteredRows, setFilter }}
      filterIsShown={filterIsShown}
      setFilterIsShown={setFilterIsShown}
    />
  );
}
