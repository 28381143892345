import React from "react";
import { Col, Form } from "react-bootstrap";
import useWindowSize from "../hooks/useWindowSize";

const FilterGroup = ({ column }) => {
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  if (!column.canRenderFilter) {
    return null;
  }
  return (
    <>
      {isDesktop && (
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>{column.render("Header")}</Form.Label>
            {column.render("Filter")}
          </Form.Group>
        </Col>
      )}
      {!isDesktop && (
        <Form.Group className="mb-3">
          <Form.Label>{column.render("Header")}</Form.Label>
          {column.render("Filter")}
        </Form.Group>
      )}
    </>
  );
};

export default FilterGroup;
