import React from "react";
import { Form, Row, Col, Button, Modal, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useWindowSize from "../../../hooks/useWindowSize";

const EditEntityForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  type,
  setValues,
  setShowEditEntity,
  initialValues,
  handleSubmit,
  countryOptions,
}) => {
  const { t } = useTranslation();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [width] = useWindowSize();
  const isMobile = width <= 480; // Covers mobile devices
  const isTablet = width > 480 && width <= 768; // Covers tablets
  const isDesktop = width > 768; // Covers desktops

  if (isDesktop) {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>{t("crm.editModal.fullName")}</Form.Label>
          <Form.Control
            type="text"
            name="fullName"
            value={values.fullName}
            isInvalid={Boolean(touched.fullName && errors.fullName)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.fullName && (
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {type !== "Subaccount" && (
          <React.Fragment>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.address")}</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={values.address}
                isInvalid={Boolean(touched.address && errors.address)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.address && (
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t("crm.editModal.city")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={values.city}
                    isInvalid={Boolean(touched.city && errors.city)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.city && (
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t("crm.editModal.country")}</Form.Label>
                  <Form.Select
                    value={values.country}
                    onChange={handleChange}
                    name="country"
                    isInvalid={Boolean(touched.country && errors.country)}
                  >
                    {countryOptions.map((option) => (
                      <option key={option.value} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  {!!touched.country && (
                    <Form.Control.Feedback type="invalid">
                      {errors.country}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t("crm.editModal.postcode")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                    value={values.postcode}
                    isInvalid={Boolean(touched.postcode && errors.postcode)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.postcode && (
                    <Form.Control.Feedback type="invalid">
                      {errors.postcode}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>{t("crm.editModal.telephone")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={values.telephoneNumber}
                    name="telephoneNumber"
                    isInvalid={Boolean(
                      touched.telephoneNumber && errors.telephoneNumber
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.telephoneNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.telephoneNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>{t("crm.editModal.mobile")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={values.mobileNumber}
                    name="mobileNumber"
                    isInvalid={Boolean(
                      touched.mobileNumber && errors.mobileNumber
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.mobileNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.mobileNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </React.Fragment>
        )}
        <Form.Group className="mb-3">
          <Form.Label>{t("crm.editModal.email")}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={values.email}
            isInvalid={Boolean(touched.email && errors.email)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {type !== "Subaccount" && type !== "Admin" && (
          <React.Fragment>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.occupation")}</Form.Label>
              <Form.Control
                type="text"
                name="occupation"
                value={values.occupation}
                isInvalid={Boolean(touched.occupation && errors.occupation)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.occupation && (
                <Form.Control.Feedback type="invalid">
                  {errors.occupation}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>{t("crm.editModal.company")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    value={values.company}
                    isInvalid={Boolean(touched.company && errors.company)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!touched.company && (
                    <Form.Control.Feedback type="invalid">
                      {errors.company}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>{t("crm.editModal.tin")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="tin"
                    value={values.tin}
                    isInvalid={Boolean(touched.tin && errors.tin)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.tin && (
                    <Form.Control.Feedback type="invalid">
                      {errors.tin}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </React.Fragment>
        )}
        <Form.Group className="mb-4">
          <Form.Label>{t("crm.editModal.username")}</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={values.username}
            isInvalid={Boolean(touched.username && errors.username)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.username && (
            <Form.Control.Feedback type="invalid">
              {errors.username}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setShowEditEntity(false)}
            className="me-2"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#fff",
              border: "none",
            }}
          >
            {t("crm.editModal.cancel")}
          </Button>
          <Button
            type="submit"
            variant="primary"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#fff",
              border: "none",
            }}
          >
            {t("crm.editModal.submit")}
          </Button>
        </Modal.Footer>
      </Form>
    );
  } else {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>{t("crm.editModal.fullName")}</Form.Label>
          <Form.Control
            type="text"
            name="fullName"
            value={values.fullName}
            isInvalid={Boolean(touched.fullName && errors.fullName)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.fullName && (
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {type !== "Subaccount" && (
          <React.Fragment>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.address")}</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={values.address}
                isInvalid={Boolean(touched.address && errors.address)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.address && (
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.city")}</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={values.city}
                isInvalid={Boolean(touched.city && errors.city)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.city && (
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.country")}</Form.Label>
              <Form.Select
                value={values.country}
                onChange={handleChange}
                name="country"
                isInvalid={Boolean(touched.country && errors.country)}
              >
                {countryOptions.map((option) => (
                  <option key={option.value} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              {!!touched.country && (
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.postcode")}</Form.Label>
              <Form.Control
                type="text"
                name="postcode"
                value={values.postcode}
                isInvalid={Boolean(touched.postcode && errors.postcode)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.postcode && (
                <Form.Control.Feedback type="invalid">
                  {errors.postcode}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.telephone")}</Form.Label>
              <Form.Control
                type="text"
                value={values.telephoneNumber}
                name="telephoneNumber"
                isInvalid={Boolean(
                  touched.telephoneNumber && errors.telephoneNumber
                )}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.telephoneNumber && (
                <Form.Control.Feedback type="invalid">
                  {errors.telephoneNumber}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.mobile")}</Form.Label>
              <Form.Control
                type="text"
                value={values.mobileNumber}
                name="mobileNumber"
                isInvalid={Boolean(touched.mobileNumber && errors.mobileNumber)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.mobileNumber && (
                <Form.Control.Feedback type="invalid">
                  {errors.mobileNumber}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </React.Fragment>
        )}
        <Form.Group className="mb-3">
          <Form.Label>{t("crm.editModal.email")}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={values.email}
            isInvalid={Boolean(touched.email && errors.email)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {type !== "Subaccount" && type !== "Admin" && (
          <React.Fragment>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.occupation")}</Form.Label>
              <Form.Control
                type="text"
                name="occupation"
                value={values.occupation}
                isInvalid={Boolean(touched.occupation && errors.occupation)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.occupation && (
                <Form.Control.Feedback type="invalid">
                  {errors.occupation}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.company")}</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={values.company}
                isInvalid={Boolean(touched.company && errors.company)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!touched.company && (
                <Form.Control.Feedback type="invalid">
                  {errors.company}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("crm.editModal.tin")}</Form.Label>
              <Form.Control
                type="text"
                name="tin"
                value={values.tin}
                isInvalid={Boolean(touched.tin && errors.tin)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.tin && (
                <Form.Control.Feedback type="invalid">
                  {errors.tin}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </React.Fragment>
        )}
        <Form.Group className="mb-3">
          <Form.Label>{t("crm.editModal.username")}</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={values.username}
            isInvalid={Boolean(touched.username && errors.username)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.username && (
            <Form.Control.Feedback type="invalid">
              {errors.username}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className="d-flex flex-column justify-content-center mt-3">
          <Button
            type="submit"
            variant="primary"
            className="mb-3"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#fff",
              border: "none",
              padding: "10px",
            }}
          >
            {t("crm.editModal.submit")}
          </Button>
          <Button
            variant="primary"
            onClick={() => setShowEditEntity(false)}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#fff",
              border: "none",
              padding: "10px",
            }}
          >
            {t("crm.editModal.cancel")}
          </Button>
        </div>
      </Form>
    );
  }
};

export default EditEntityForm;
