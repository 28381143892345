import React, { useState, useEffect, useContext } from "react";
import { Col, Form, Modal, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { OffersLane } from "./OffersCard";
import dragula from "react-dragula";
import AssignOffersForm from "./AssignOffersForm";
import {
  assignOffers,
  getAssignedOffersForUser,
  getAvailableOffers,
  getAvailableOffersForUser,
  updateOffers,
} from "../../../redux/slices/offers";
import NotyfContext from "../../../contexts/NotyfContext";
import Loader from "../../../components/spinners/Loader";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";
const containers = [];

const AssignOffersModal = ({
  showAssignOffersModal,
  setShowAssignOffersModal,
  entityUser,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const availableOffers = useSelector((state) => state.offers.availableOffers);
  const assignedOffers = useSelector((state) => state.offers.assignedOffers);
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(showAssignOffersModal);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (showAssignOffersModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showAssignOffersModal]);

  const handleHide = () => {
    setFadeOut(true); // Trigger fade-out animation
    setTimeout(() => {
      setInternalShow(false); // Unmount after animation completes
      setShowAssignOffersModal(false); // Notify parent
    }, 300); // Match the animation duration
  };

  const initialValues = {
    availableOffers: availableOffers.filter(
      (offer) =>
        !assignedOffers.some((assignedOffer) => assignedOffer.id === offer.id)
    ),
    offers: isDesktop
      ? assignedOffers
      : assignedOffers.map((offer) => offer.id),
  };

  const validation = Yup.object().shape({
    offers: Yup.array().required("Please select at least one offer"),
  });

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchAvailableOffers = async () => {
      await Promise.all([
        dispatch(getAvailableOffers()),
        dispatch(getAssignedOffersForUser(entityUser.id)),
      ])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id && entityUser.id && showAssignOffersModal) {
      fetchAvailableOffers();
    }
  }, [activeUser, entityUser, showAssignOffersModal]);

  if (isDesktop) {
    return (
      <Modal
        show={showAssignOffersModal}
        onHide={() => setShowAssignOffersModal(false)}
        centered
        backdrop="static"
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("crm.offersModal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader />}
          {!isLoading && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={async (values, { setSubmitting, setValues }) => {
                const data = {
                  offerIds: values.offers.map((offer) => offer.id),
                };
                await dispatch(updateOffers({ id: entityUser.id, data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Offers assigned successfully",
                      duration: 5000,
                      dismissible: false,
                      ripple: true,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      dismissible: false,
                      ripple: true,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setShowAssignOffersModal(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <AssignOffersForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    availableOffers={availableOffers}
                  />
                );
              }}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        backdrop="static"
        scrollable={true}
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>{t("crm.offersModal.title")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setSubmitting, setValues }) => {
              const data = {
                offerIds: values.offers.map((offer) => offer.id),
              };
              await dispatch(updateOffers({ id: entityUser.id, data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Offers assigned successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setValues(initialValues);
                  setShowAssignOffersModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <AssignOffersForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  availableOffers={availableOffers}
                  handleHide={handleHide}
                />
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default AssignOffersModal;
