import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import defaultUser from "../../../assets/img/avatars/default-user.png";
import { FaUpload } from "react-icons/fa";
import NotyfContext from "../../../contexts/NotyfContext";
import { updateProfileImage } from "../../../redux/slices/images";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";

const ProfileImageSettings = ({ activeTab }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [preview, setPreview] = useState(defaultUser);
  const inputRef = useRef(null);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  useEffect(() => {
    if (activeTab === "#ProfileImage") {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [activeTab]);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    if (activeUser.photo) {
      setPreview(
        `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${activeUser.photo}`
      );
    } else {
      setPreview(defaultUser);
    }
  }, [activeUser]);

  const initialValues = {
    image: activeUser.photo,
  };

  const validation = Yup.object().shape({
    image: Yup.mixed(),
  });

  const handleUpload = (ref) => {
    ref.current?.click();
  };

  const handleDisplayFileDetails = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreview(reader.result);
        setFieldValue("image", file);
      }
    };
    reader.onerror = () => {
      notyf.open({
        type: "danger",
        message: reader.error,
        duration: 5000,
        ripple: true,
        dismissible: false,
        position: {
          x: "center",
          y: "top",
        },
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Card>
      <Card.Header className="mb-0">
        <Card.Title>{t("settings.profileImageSettings.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values) => {
              await dispatch(updateProfileImage({ data: values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Profile Image successfully updated",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {isDesktop && (
                    <Form.Group className="mb-3 d-flex flex-column justify-content-center align-items-center">
                      <Image
                        src={preview}
                        alt="profile image"
                        roundedCircle
                        width={150}
                        height={150}
                      />
                      <input
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleDisplayFileDetails(e, setFieldValue)
                        }
                      />
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                          padding: "5px 15px",
                        }}
                        onClick={() => handleUpload(inputRef)}
                        className="mt-3"
                      >
                        <FaUpload /> {t("settings.profileImageSettings.upload")}
                      </Button>
                    </Form.Group>
                  )}
                  {!isDesktop && (
                    <Form.Group className="mb-3 d-flex flex-column justify-content-center align-items-center">
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                          width: "100%",
                          boxShadow:
                            "inset 0 2px 4px 2px rgba(0, 0, 0, 0.1), inset 0 -1px 3px rgba(255, 255, 255, 0.2)",
                          transition: "box-shadow 0.2s ease",
                          padding: "15px",
                          borderRadius: "20px",
                          backgroundColor: "rgb(235 235 235 / 30%)",
                        }}
                      >
                        <Image
                          src={preview}
                          alt="profile image"
                          roundedCircle
                          width={150}
                          height={150}
                          style={{
                            boxShadow: "0 2px 8px rgba(0,0,0,0.25)",
                          }}
                        />
                        <input
                          ref={inputRef}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleDisplayFileDetails(e, setFieldValue)
                          }
                        />
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                            padding: "5px 15px",
                            boxShadow: "2px 2px 4px rgba(0,0,0,0.25)",
                          }}
                          onClick={() => handleUpload(inputRef)}
                          className="mt-3"
                        >
                          <FaUpload />{" "}
                          {t("settings.profileImageSettings.upload")}
                        </Button>
                      </div>
                    </Form.Group>
                  )}
                  {isDesktop && (
                    <Row>
                      <Col md="auto ms-auto">
                        <Button
                          type="submit"
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("settings.profileImageSettings.save")}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {!isDesktop && (
                    <div className="d-flex justify-content-center mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        {t("settings.profileImageSettings.save")}
                      </Button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProfileImageSettings;
