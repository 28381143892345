import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import dragula from "react-dragula";
import { OffersCard, OffersLane } from "./OffersCard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";
import Select from "react-select";

const AssignOffersForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  availableOffers,
  handleHide = null,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const availableOffersRef = useRef(null);
  const assignedOffersRef = useRef(null);
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  useEffect(() => {
    const containers = [availableOffersRef.current, assignedOffersRef.current];
    const drake = dragula(containers);

    drake.on("drop", (el, target, source, sibling) => {
      drake.cancel(true);
      const sourceId = source.id;
      const targetId = target.id;

      const draggedOfferId = parseInt(el.getAttribute("data-id"));

      if (targetId === "assigned-offers" && sourceId === "available-offers") {
        const draggedOffer = values.availableOffers.find((offer) => {
          return Number(offer.id) === Number(draggedOfferId);
        });
        // Move from available to assigned
        const updatedAvailableOffers = values.availableOffers.filter(
          (offer) => Number(offer.id) !== Number(draggedOfferId)
        );
        const updatedAssignedOffers = [...values.offers, draggedOffer];
        setFieldValue("availableOffers", updatedAvailableOffers);
        setFieldValue("offers", updatedAssignedOffers);
      } else if (
        targetId === "available-offers" &&
        sourceId === "assigned-offers"
      ) {
        const draggedOffer = values.offers.find((offer) => {
          return Number(offer.id) === Number(draggedOfferId);
        });
        // Move from assigned to available
        const updatedAssignedOffers = values.offers.filter(
          (offer) => Number(offer.id) !== Number(draggedOfferId)
        );
        const updatedAvailableOffers = [
          ...values.availableOffers,
          draggedOffer,
        ];

        setFieldValue("offers", updatedAssignedOffers);
        setFieldValue("availableOffers", updatedAvailableOffers);
      }
    });

    return () => {
      drake.destroy();
    };
  }, [values.availableOffers, values.offers, setFieldValue]);

  const options = availableOffers.map((offer) => {
    return { value: offer.id, label: offer.name };
  });

  if (isDesktop) {
    return (
      <Form onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col lg={6} md={12} className="d-flex">
              <OffersLane
                id="available-offers"
                name={t("crm.offersModal.availableOffers")}
                onContainerLoaded={(ref) => (availableOffersRef.current = ref)}
              >
                {values.availableOffers.map((offer, index) => (
                  <OffersCard id={offer?.id} key={index} text={offer?.name} />
                ))}
              </OffersLane>
            </Col>
            <Col lg={6} md={12} className="d-flex">
              <OffersLane
                id="assigned-offers"
                name={t("crm.offersModal.assignedOffers")}
                onContainerLoaded={(ref) => (assignedOffersRef.current = ref)}
              >
                {values.offers.length > 0 ? (
                  values.offers.map((offer) => (
                    <OffersCard
                      key={offer?.id}
                      id={offer.id}
                      text={offer?.name}
                    />
                  ))
                ) : (
                  <div className="w-100 h-100">No offers assigned</div>
                )}
              </OffersLane>
            </Col>
          </Row>
        </Container>
        <Modal.Footer>
          <Button
            type="submit"
            variant="primary"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#fff",
              border: "none",
            }}
          >
            {t("crm.offersModal.submit")}
          </Button>
        </Modal.Footer>
      </Form>
    );
  } else {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>{t("crm.offersModal.assignedOffers")}</Form.Label>
          <Select
            name="offers"
            isMulti
            value={options.filter((option) =>
              values.offers.includes(option.value)
            )}
            options={options}
            onChange={(selectedOptions) => {
              setFieldValue(
                "offers",
                selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : []
              );
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                maxHeight: "100px", // Limit the height of the control
                overflowY: "auto", // Enable scrolling when options overflow
                borderRadius: "20px",
              }),
              menu: (base) => ({
                ...base,
                maxHeight: "200px", // Limit the height of the dropdown menu
                overflowY: "auto", // Enable scrolling in the dropdown
                backgroundColor: "#fff",
                zIndex: 9999,
                borderRadius: "20px",
              }),
              option: (provided, state) => ({
                ...provided,
                borderRadius: "20px",
                backgroundColor: state.isSelected
                  ? "#007bff"
                  : state.isFocused
                  ? "#e9ecef"
                  : "#fff",
                color: state.isSelected ? "#fff" : "#333",
                padding: "10px",
              }),
              multiValue: (base) => ({
                ...base,
                maxWidth: "100%", // Prevent multi-value items from overflowing
                color: "#333",
              }),
            }}
          />
        </Form.Group>
        <div className="d-flex flex-column justify-content-center mt-3">
          <Button
            variant="primary"
            type="submit"
            className="mb-3"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              padding: "10px",
            }}
          >
            {t("crm.offersModal.submit")}
          </Button>
          <Button
            variant="primary"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              padding: "10px",
            }}
            onClick={handleHide}
          >
            {t("crm.offersModal.cancel")}
          </Button>
        </div>
      </Form>
    );
  }
};

export default AssignOffersForm;
