import React, { useState, useContext, useEffect } from "react";
import { Card, Form, Row, Button, Col, Accordion } from "react-bootstrap";
import FilterGroup from "./FilterGroup";
import { getSims, getSimInventoryCount } from "../../../redux/slices/sims";
import { useSelector, useDispatch } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";
import { MdSort } from "react-icons/md";
import {
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaSortNumericDown,
  FaSortNumericUp,
} from "react-icons/fa";
import useWindowSize from "../../../hooks/useWindowSize";

const FiltersCard = ({
  headerGroups,
  filters,
  setFilters,
  page,
  pageSize,
  setIsLoading,
  customFields,
  sort,
  setSort,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  const sortingOptions = [
    {
      icon: FaSortNumericUp,
      label: "IMSI (Asc)",
      value: { sortField: "imsi", sortOrder: "asc" },
    },
    {
      icon: FaSortNumericDown,
      label: "IMSI (Desc)",
      value: { sortField: "imsi", sortOrder: "desc" },
    },
    {
      icon: FaSortNumericUp,
      label: "ICCID (Asc)",
      value: { sortField: "iccid", sortOrder: "asc" },
    },
    {
      icon: FaSortNumericDown,
      label: "ICCID (Desc)",
      value: { sortField: "iccid", sortOrder: "desc" },
    },
    {
      icon: FaSortNumericUp,
      label: "MSISDN (Asc)",
      value: { sortField: "msisdn", sortOrder: "asc" },
    },
    {
      icon: FaSortNumericDown,
      label: "MSISDN (Desc)",
      value: { sortField: "msisdn", sortOrder: "desc" },
    },
    {
      icon: FaSortAlphaUp,
      label: "State (Asc)",
      value: { sortField: "state", sortOrder: "asc" },
    },
    {
      icon: FaSortAlphaDown,
      label: "State (Desc)",
      value: { sortField: "state", sortOrder: "desc" },
    },
    {
      icon: FaSortAlphaUp,
      label: "Offer (Asc)",
      value: { sortField: "offer", sortOrder: "asc" },
    },
    {
      icon: FaSortAlphaDown,
      label: "Offer (Desc)",
      value: { sortField: "offer", sortOrder: "desc" },
    },
    {
      icon: FaSortNumericUp,
      label: "Data (Asc)",
      value: { sortField: "data", sortOrder: "asc" },
    },
    {
      icon: FaSortNumericDown,
      label: "Data (Desc)",
      value: { sortField: "data", sortOrder: "desc" },
    },
  ];

  const handleSortChange = (event) => {
    const selectedSort = JSON.parse(event.target.value);
    setSort(selectedSort);
  };

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const handleSearch = async () => {
    setIsLoading(true);
    if (activeUser.id) {
      await Promise.all([
        dispatch(
          getSims({
            page: page + 1,
            pageSize,
            filters,
            sortField: sort.sortField,
            sortOrder: sort.sortOrder,
          })
        ),
      ])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClear = async () => {
    const newFilters = {};
    setFilters(newFilters);
    setSort({ sortField: "id", sortOrder: "asc" });
    setIsLoading(true);
    if (activeUser.id) {
      await Promise.all([
        dispatch(getSims({ page: page + 1, pageSize, filters: newFilters })),
      ])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Card>
      {isDesktop && (
        <Card.Header>
          <Card.Title>{t("sims.filters")}</Card.Title>
        </Card.Header>
      )}
      <Card.Body>
        <Form>
          {isDesktop && (
            <>
              <Row>
                {headerGroups.map((headerGroup, idx) =>
                  headerGroup.headers.map((column, index) => (
                    <FilterGroup
                      key={index}
                      column={column}
                      filters={filters}
                      setFilters={setFilters}
                      page={page}
                      pageSize={pageSize}
                    />
                  ))
                )}
                <Form.Group as={Col}>
                  <Form.Label>{t("sims.sort")}</Form.Label>
                  <Form.Select
                    onChange={handleSortChange}
                    value={JSON.stringify(sort)}
                  >
                    {sortingOptions.map((option, index) => (
                      <option key={index} value={JSON.stringify(option.value)}>
                        {option.icon}
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
              <div className="d-flex mt-2 justify-content-end">
                <Button
                  className="me-3"
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                  onClick={handleSearch}
                >
                  {t("sims.search")}
                </Button>
                <Button
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                  onClick={handleClear}
                >
                  {t("sims.clear")}
                </Button>
              </div>
            </>
          )}
          {!isDesktop && (
            <Accordion className="filters-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("sims.filters")}</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {headerGroups.map((headerGroup, idx) =>
                      headerGroup.headers.map((column, index) => (
                        <FilterGroup
                          key={index}
                          column={column}
                          filters={filters}
                          setFilters={setFilters}
                          page={page}
                          pageSize={pageSize}
                        />
                      ))
                    )}
                    <Form.Group as={Col}>
                      <Form.Label>{t("sims.sort")}</Form.Label>
                      <Form.Select
                        onChange={handleSortChange}
                        value={JSON.stringify(sort)}
                      >
                        {sortingOptions.map((option, index) => (
                          <option
                            key={index}
                            value={JSON.stringify(option.value)}
                          >
                            {option.icon}
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <div className="d-flex flex-column mt-3 justify-content-center">
                    <Button
                      className="mb-3"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                        padding: "10px",
                      }}
                      onClick={handleSearch}
                    >
                      {t("sims.search")}
                    </Button>
                    <Button
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                        padding: "10px",
                      }}
                      onClick={handleClear}
                    >
                      {t("sims.clear")}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FiltersCard;
