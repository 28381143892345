import React, { useEffect, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import dragula from "react-dragula";
import useWindowSize from "../../../hooks/useWindowSize";
import Select from "react-select";

const PermissionsLane = ({ id, name, children, onContainerLoaded }) => {
  const handleContainerLoaded = (container) => {
    if (container) {
      onContainerLoaded(container);
    }
  };

  return (
    <Card className="flex-fill w-100 d-flex">
      <Card.Header>
        <Card.Title>{name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div ref={handleContainerLoaded} id={id} className="h-100">
          {children}
        </div>
      </Card.Body>
    </Card>
  );
};

const PermissionsCard = ({ id, text }) => (
  <Card className="mb-3 bg-light cursor-grab border" data-id={id}>
    <Card.Body className="p-3">
      <p>{text}</p>
    </Card.Body>
  </Card>
);

const AssignPermissionsForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  availablePrivileges,
  setShowAssignPermissionsModal,
  setValues,
  initialValues,
  handleHide = null,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const availablePrivilegesRef = useRef(null);
  const assignedPrivilegesRef = useRef(null);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  useEffect(() => {
    const containers = [
      availablePrivilegesRef.current,
      assignedPrivilegesRef.current,
    ];
    const drake = dragula(containers);

    drake.on("drop", (el, target, source, sibling) => {
      drake.cancel(true);
      const sourceId = source.id;
      const targetId = target.id;
      const draggedPrivilegeId = parseInt(el.getAttribute("data-id"));
      if (
        targetId === "assigned-permissions" &&
        sourceId === "available-permissions"
      ) {
        const draggedPrivilege = values.availablePrivileges.find(
          (privilege) => {
            return Number(privilege.id) === Number(draggedPrivilegeId);
          }
        );
        const updatedAvailablePrivileges = values.availablePrivileges.filter(
          (privilege) => Number(privilege.id) !== Number(draggedPrivilegeId)
        );
        const updatedAssignedPrivileges = [
          ...values.privileges,
          draggedPrivilege,
        ];
        setFieldValue("availablePrivileges", updatedAvailablePrivileges);
        setFieldValue("privileges", updatedAssignedPrivileges);
      } else if (
        targetId === "available-permissions" &&
        sourceId === "assigned-permissions"
      ) {
        const draggedPrivilege = values.privileges.find((privilege) => {
          return Number(privilege.id) === Number(draggedPrivilegeId);
        });
        const updatedAssignedPrivileges = values.privileges.filter(
          (privilege) => Number(privilege.id) !== Number(draggedPrivilegeId)
        );
        const updatedAvailablePrivileges = [
          ...values.availablePrivileges,
          draggedPrivilege,
        ];
        setFieldValue("privileges", updatedAssignedPrivileges);
        setFieldValue("availablePrivileges", updatedAvailablePrivileges);
      }
    });

    return () => {
      drake.destroy();
    };
  }, [values.availablePrivileges, values.privileges, setFieldValue]);

  const options = availablePrivileges.map((privilege) => {
    return { value: privilege.id, label: privilege.name };
  });

  if (isDesktop) {
    return (
      <Form onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col lg={6} md={12} className="d-flex">
              <PermissionsLane
                id="available-permissions"
                name="Available Permissions"
                onContainerLoaded={(container) =>
                  (availablePrivilegesRef.current = container)
                }
              >
                {values.availablePrivileges.map((privilege) => (
                  <PermissionsCard
                    id={privilege.id}
                    text={privilege.name}
                    key={privilege.id}
                  />
                ))}
              </PermissionsLane>
            </Col>
            <Col lg={6} md={12} className="d-flex">
              <PermissionsLane
                id="assigned-permissions"
                name="Assigned Permissions"
                onContainerLoaded={(container) =>
                  (assignedPrivilegesRef.current = container)
                }
              >
                {values.privileges.length > 0 ? (
                  values.privileges.map((privilege) => (
                    <PermissionsCard
                      key={privilege.id}
                      id={privilege.id}
                      text={privilege.name}
                    />
                  ))
                ) : (
                  <div className="w-100 h-100">No offers assigned</div>
                )}
              </PermissionsLane>
            </Col>
          </Row>
        </Container>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setValues(initialValues);
              setShowAssignPermissionsModal(false);
            }}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            type="submit"
            disabled={values.privileges.length === 0}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    );
  } else {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Assigned Permissions</Form.Label>
          <Select
            name="privileges"
            isMulti
            value={options.filter((option) =>
              values.privileges.includes(option.value)
            )}
            options={options}
            onChange={(selectedOptions) => {
              setFieldValue(
                "privileges",
                selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : []
              );
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                maxHeight: "100px", // Limit the height of the control
                overflowY: "auto", // Enable scrolling when options overflow
                borderRadius: "20px",
              }),
              menu: (base) => ({
                ...base,
                maxHeight: "200px", // Limit the height of the dropdown menu
                overflowY: "auto", // Enable scrolling in the dropdown
                backgroundColor: "#fff",
                zIndex: 9999,
                borderRadius: "20px",
              }),
              option: (provided, state) => ({
                ...provided,
                borderRadius: "20px",
                backgroundColor: state.isSelected
                  ? "#007bff"
                  : state.isFocused
                  ? "#e9ecef"
                  : "#fff",
                color: state.isSelected ? "#fff" : "#333",
                padding: "10px",
              }),
              multiValue: (base) => ({
                ...base,
                maxWidth: "100%", // Prevent multi-value items from overflowing
                color: "#333",
              }),
            }}
          />
        </Form.Group>
        <div className="d-flex flex-column justify-content-center mt-3">
          <Button
            variant="primary"
            type="submit"
            className="mb-3"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              padding: "10px",
            }}
          >
            Submit
          </Button>
          <Button
            variant="primary"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              padding: "10px",
            }}
            onClick={handleHide}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
};

export default AssignPermissionsForm;
