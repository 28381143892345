import React, { useState, useRef, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Alert, Button, Offcanvas } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";

import NotyfContext from "../../../contexts/NotyfContext";
import { addNewCustomer } from "../../../redux/slices/customers";
import { addNewManager } from "../../../redux/slices/managers";
import { addNewTenant } from "../../../redux/slices/tenants";
import { addNewSubaccount } from "../../../redux/slices/subaccounts";
import defaultUser from "../../../assets/img/avatars/default-user.png";
import Basic from "./steps/Basic";
import Contact from "./steps/Contact";
import EmploymentAndTax from "./steps/Employment";
import OffersStep from "./steps/Offers";
import Billing from "./steps/Billing";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";

function CreateEntityModal(props) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [image, setImage] = useState(defaultUser);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [currentStep, setCurrentStep] = useState(1);
  const [offers, setOffers] = useState([]);
  const [internalShow, setInternalShow] = useState(props.showCreateEntityModal);
  const [fadeOut, setFadeOut] = useState(false);
  const [width] = useWindowSize();
  const isDesktop = width > 768; // Covers desktops

  useEffect(() => {
    if (props.showCreateEntityModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [props.showCreateEntityModal]);

  const handleHide = () => {
    setFadeOut(true); // Trigger fade-out animation
    setTimeout(() => {
      setInternalShow(false); // Unmount after animation completes
      props.setShowCreateEntityModal(false); // Notify parent
    }, 300); // Match the animation duration
  };

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    const file = inputRef.current?.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.onerror = () => {
      console.log(reader.error);
    };
    reader.readAsDataURL(inputRef.current.files[0]);
  };

  const initialValues = {
    fullName: "",
    address: "",
    email: "",
    postcode: "",
    city: "",
    country: "",
    telephoneNumber: "",
    mobileNumber: "",
    status: "",
    occupation: "",
    company: "",
    tin: "",
    portfolioId: "",
    sendWelcomeEmail: false,
    minimumAmountToInvoice: "20",
    invoiceType: "",
    invoiceHandlerType: "auto",
    currency: "EUR",
    invoiceConfiguration: "skytrackInvoice",
    preferredLanguage: "english",
    billingEmail: "",
  };

  const BasicValidation = Yup.object().shape({
    fullName: Yup.string().max(255).required("Full Name is required"),
    address:
      props.entityType !== "Subaccount"
        ? Yup.string().max(255, "Address must be at most 255 characters")
        : Yup.string().nullable(),
    postcode:
      props.entityType !== "Subaccount"
        ? Yup.string().max(12, "Must be at most 12 characters")
        : Yup.string().nullable(),
    city:
      props.entityType !== "Subaccount"
        ? Yup.string().required("City is required")
        : Yup.string().nullable(),
    country:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Country is required")
        : Yup.string().nullable(),
    portfolioId:
      props.entityType !== "Subaccount"
        ? Yup.string()
            .matches(/^[0-9]*$/, "Portfolio ID must contain only numbers")
            .required("Portfolio ID is required")
        : Yup.string().nullable(),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    telephoneNumber:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Telephone Number is required")
        : Yup.string().nullable(),
    mobileNumber:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Mobile Number is required")
        : Yup.string().nullable(),
    occupation:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Occupation is required")
        : Yup.string().nullable(),
    tin:
      props.entityType !== "Subaccount"
        ? Yup.string().required("TIN is required")
        : Yup.string().nullable(),
    sendWelcomeEmail: Yup.boolean(),
  });

  const BillingValidation = Yup.object().shape({
    minimumAmountToInvoice:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Minimum Amount to Invoice is required")
        : Yup.string().nullable(),
    invoiceType:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Invoice Type is required")
        : Yup.string().nullable(),
    invoiceHandlerType:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Invoice Handler Type is required")
        : Yup.string().nullable(),
    currency:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Currency is required")
        : Yup.string().nullable(),
    invoiceConfiguration:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Invoice Configuration is required")
        : Yup.string().nullable(),
    preferredLanguage:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Preferred Language is required")
        : Yup.string().nullable(),
    billingEmail:
      props.entityType !== "Subaccount"
        ? Yup.string().required("Billing Email is required")
        : Yup.string().nullable(),
  });

  if (isDesktop) {
    return (
      <Modal
        show={props.showCreateEntityModal}
        centered
        backdrop="static"
        size="lg"
        scrollable
        onHide={() => props.setShowCreateEntityModal(false)}
      >
        <Modal.Header closeButton>
          {props.entityType === "Tenant"
            ? t("crm.newModal.newTenant")
            : props.entityType === "Manager"
            ? t("crm.newModal.newManager")
            : props.entityType === "Customer"
            ? t("crm.newModal.newCustomer")
            : props.entityType === "Subaccount"
            ? t("crm.newModal.newSubaccount")
            : null}
        </Modal.Header>
        <Modal.Body>
          <Formik
            formikProps
            initialValues={initialValues}
            validationSchema={
              currentStep === 1 ? BasicValidation : BillingValidation
            }
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, setValues }
            ) => {
              if (props.entityType === "Tenant") {
                const data = {
                  fullName: values.fullName,
                  address: values.address,
                  city: values.city,
                  country: values.country,
                  postcode: values.postcode,
                  telephoneNumber: values.telephoneNumber,
                  mobileNumber: values.mobileNumber,
                  email: values.email,
                  occupation: values.occupation,
                  company: values.company,
                  tin: values.tin,
                  username: values.username,
                  portfolioId: values.portfolioId,
                  minimumAmountToInvoice: values.minimumAmountToInvoice,
                  invoiceType: values.invoiceType,
                  invoiceHandlerType: values.invoiceHandlerType,
                  currency: values.currency,
                  invoiceConfiguration: values.invoiceConfiguration,
                  preferredLanguage: values.preferredLanguage,
                  billingEmail: values.billingEmail,
                  sendWelcomeEmail: values.sendWelcomeEmail,
                };
                await dispatch(addNewTenant({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Tenant successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              } else if (props.entityType === "Manager") {
                const data = {
                  fullName: values.fullName,
                  address: values.address,
                  city: values.city,
                  country: values.country,
                  postcode: values.postcode,
                  telephoneNumber: values.telephoneNumber,
                  mobileNumber: values.mobileNumber,
                  email: values.email,
                  occupation: values.occupation,
                  company: values.company,
                  tin: values.tin,
                  username: values.username,
                  portfolioId: values.portfolioId,
                  minimumAmountToInvoice: values.minimumAmountToInvoice,
                  invoiceType: values.invoiceType,
                  invoiceHandlerType: values.invoiceHandlerType,
                  currency: values.currency,
                  invoiceConfiguration: values.invoiceConfiguration,
                  preferredLanguage: values.preferredLanguage,
                  billingEmail: values.billingEmail,
                  sendWelcomeMail: values.sendWelcomeEmail,
                };
                dispatch(addNewManager({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Tenant successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              } else if (props.entityType === "Customer") {
                const data = {
                  fullName: values.fullName,
                  address: values.address,
                  city: values.city,
                  country: values.country,
                  postcode: values.postcode,
                  telephoneNumber: values.telephoneNumber,
                  mobileNumber: values.mobileNumber,
                  email: values.email,
                  occupation: values.occupation,
                  company: values.company,
                  tin: values.tin,
                  username: values.username,
                  portfolioId: values.portfolioId,
                  minimumAmountToInvoice: values.minimumAmountToInvoice,
                  invoiceType: values.invoiceType,
                  invoiceHandlerType: values.invoiceHandlerType,
                  currency: values.currency,
                  invoiceConfiguration: values.invoiceConfiguration,
                  preferredLanguage: values.preferredLanguage,
                  billingEmail: values.billingEmail,
                  sendWelcomeMail: values.sendWelcomeEmail,
                };
                await dispatch(addNewCustomer({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Customer successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              } else if (props.entityType === "Subaccount") {
                const data = {
                  fullName: values.fullName,
                  email: values.email,
                  username: values.username,
                  sendWelcomeEmail: values.sendWelcomeEmail,
                };
                await dispatch(addNewSubaccount({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Subaccount successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              setFieldTouched,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setValues,
              validateForm,
              setFieldValue,
            }) => {
              const nextStep = () => {
                validateForm().then((errors) => {
                  // Only transition to the next step if there are no errors
                  if (Object.keys(errors).length === 0) {
                    if (
                      currentStep === 1 &&
                      props.entityType === "Subaccount"
                    ) {
                      handleSubmit();
                    } else if (
                      currentStep === 1 &&
                      props.entityType !== "Subaccount"
                    ) {
                      setCurrentStep(currentStep + 1);
                    }
                    if (currentStep === 2) {
                      handleSubmit();
                    }
                  } else {
                    // Force all fields to touched
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              };
              return (
                <React.Fragment>
                  <Form onSubmit={handleSubmit}>
                    {currentStep === 1 && (
                      <Basic
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        entityType={props.entityType}
                        image={image}
                        inputRef={inputRef}
                        handleDisplayFileDetails={handleDisplayFileDetails}
                        handleUpload={handleUpload}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {currentStep === 2 && props.entityType !== "Subaccount" && (
                      <Billing
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    <Modal.Footer>
                      {currentStep === 1 && (
                        <Button
                          variant="primary"
                          onClick={() => props.setShowCreateEntityModal(false)}
                          className="me-2"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("crm.newModal.cancel")}
                        </Button>
                      )}
                      {currentStep === 1 && props.entityType !== "Subaccount" && (
                        <Button
                          variant="primary"
                          onClick={nextStep}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("crm.newModal.next")}
                        </Button>
                      )}
                      {currentStep === 1 && props.entityType === "Subaccount" && (
                        <Button
                          variant="primary"
                          onClick={nextStep}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("crm.newModal.submit")}
                        </Button>
                      )}
                      {currentStep === 2 && (
                        <React.Fragment>
                          <Button
                            className="me-2"
                            variant="primary"
                            onClick={() => setCurrentStep(currentStep - 1)}
                            style={{
                              "--dynamic-bg-color":
                                whitelabel?.backgroundColor ?? "#00acdc",
                              "--dynamic-font-color":
                                whitelabel?.fontColor ?? "#fff",
                              border: "none",
                            }}
                          >
                            {t("crm.newModal.previous")}
                          </Button>
                          <Button
                            variant="primary"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color":
                                whitelabel?.backgroundColor ?? "#00acdc",
                              "--dynamic-font-color":
                                whitelabel?.fontColor ?? "#fff",
                              border: "none",
                            }}
                          >
                            {t("crm.newModal.submit")}
                          </Button>
                        </React.Fragment>
                      )}
                    </Modal.Footer>
                  </Form>
                </React.Fragment>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        backdrop="static"
        scrollable={true}
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>
            {props.entityType === "Tenant"
              ? t("crm.newModal.newTenant")
              : props.entityType === "Manager"
              ? t("crm.newModal.newManager")
              : props.entityType === "Customer"
              ? t("crm.newModal.newCustomer")
              : props.entityType === "Subaccount"
              ? t("crm.newModal.newSubaccount")
              : null}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            initialValues={initialValues}
            validationSchema={
              currentStep === 1 ? BasicValidation : BillingValidation
            }
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, setValues }
            ) => {
              if (props.entityType === "Tenant") {
                const data = {
                  fullName: values.fullName,
                  address: values.address,
                  city: values.city,
                  country: values.country,
                  postcode: values.postcode,
                  telephoneNumber: values.telephoneNumber,
                  mobileNumber: values.mobileNumber,
                  email: values.email,
                  occupation: values.occupation,
                  company: values.company,
                  tin: values.tin,
                  username: values.username,
                  portfolioId: values.portfolioId,
                  minimumAmountToInvoice: values.minimumAmountToInvoice,
                  invoiceType: values.invoiceType,
                  invoiceHandlerType: values.invoiceHandlerType,
                  currency: values.currency,
                  invoiceConfiguration: values.invoiceConfiguration,
                  preferredLanguage: values.preferredLanguage,
                  billingEmail: values.billingEmail,
                  sendWelcomeEmail: values.sendWelcomeEmail,
                };
                await dispatch(addNewTenant({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Tenant successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              } else if (props.entityType === "Manager") {
                const data = {
                  fullName: values.fullName,
                  address: values.address,
                  city: values.city,
                  country: values.country,
                  postcode: values.postcode,
                  telephoneNumber: values.telephoneNumber,
                  mobileNumber: values.mobileNumber,
                  email: values.email,
                  occupation: values.occupation,
                  company: values.company,
                  tin: values.tin,
                  username: values.username,
                  portfolioId: values.portfolioId,
                  minimumAmountToInvoice: values.minimumAmountToInvoice,
                  invoiceType: values.invoiceType,
                  invoiceHandlerType: values.invoiceHandlerType,
                  currency: values.currency,
                  invoiceConfiguration: values.invoiceConfiguration,
                  preferredLanguage: values.preferredLanguage,
                  billingEmail: values.billingEmail,
                  sendWelcomeMail: values.sendWelcomeEmail,
                };
                dispatch(addNewManager({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Tenant successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              } else if (props.entityType === "Customer") {
                const data = {
                  fullName: values.fullName,
                  address: values.address,
                  city: values.city,
                  country: values.country,
                  postcode: values.postcode,
                  telephoneNumber: values.telephoneNumber,
                  mobileNumber: values.mobileNumber,
                  email: values.email,
                  occupation: values.occupation,
                  company: values.company,
                  tin: values.tin,
                  username: values.username,
                  portfolioId: values.portfolioId,
                  minimumAmountToInvoice: values.minimumAmountToInvoice,
                  invoiceType: values.invoiceType,
                  invoiceHandlerType: values.invoiceHandlerType,
                  currency: values.currency,
                  invoiceConfiguration: values.invoiceConfiguration,
                  preferredLanguage: values.preferredLanguage,
                  billingEmail: values.billingEmail,
                  sendWelcomeMail: values.sendWelcomeEmail,
                };
                await dispatch(addNewCustomer({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Customer successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              } else if (props.entityType === "Subaccount") {
                const data = {
                  fullName: values.fullName,
                  email: values.email,
                  username: values.username,
                  sendWelcomeEmail: values.sendWelcomeEmail,
                };
                await dispatch(addNewSubaccount({ values: data }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Subaccount successfully created",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .finally(() => {
                    setValues(initialValues);
                    setCurrentStep(1);
                    props.setShowCreateEntityModal(false);
                  });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              setFieldTouched,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setValues,
              validateForm,
              setFieldValue,
            }) => {
              const nextStep = () => {
                validateForm().then((errors) => {
                  // Only transition to the next step if there are no errors
                  if (Object.keys(errors).length === 0) {
                    if (
                      currentStep === 1 &&
                      props.entityType === "Subaccount"
                    ) {
                      handleSubmit();
                    } else if (
                      currentStep === 1 &&
                      props.entityType !== "Subaccount"
                    ) {
                      setCurrentStep(currentStep + 1);
                    }
                    if (currentStep === 2) {
                      handleSubmit();
                    }
                  } else {
                    // Force all fields to touched
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              };

              return (
                <Form onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <Basic
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      entityType={props.entityType}
                      image={image}
                      inputRef={inputRef}
                      handleDisplayFileDetails={handleDisplayFileDetails}
                      handleUpload={handleUpload}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {currentStep === 2 && props.entityType !== "Subaccount" && (
                    <Billing
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  <div className="d-flex justify-content-center flex-column mt-3">
                    {currentStep === 1 && props.entityType !== "Subaccount" && (
                      <Button
                        variant="primary"
                        onClick={nextStep}
                        className="mb-2"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("crm.newModal.next")}
                      </Button>
                    )}
                    {currentStep === 1 && props.entityType === "Subaccount" && (
                      <Button
                        variant="primary"
                        onClick={nextStep}
                        className="mb-2"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("crm.newModal.submit")}
                      </Button>
                    )}
                    {currentStep === 1 && (
                      <Button
                        variant="primary"
                        onClick={() => props.setShowCreateEntityModal(false)}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("crm.newModal.cancel")}
                      </Button>
                    )}
                    {currentStep === 2 && (
                      <React.Fragment>
                        <Button
                          variant="primary"
                          className="mb-2"
                          onClick={nextStep}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("crm.newModal.submit")}
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("crm.newModal.previous")}
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
}

export default CreateEntityModal;
