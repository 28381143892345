import React from "react";
import { Row, Col, Form, Pagination } from "react-bootstrap";
import useWindowSize from "../../../hooks/useWindowSize";

const SimPagination = ({
  pageNumber,
  numberOfRecords,
  pageRecords,
  setPageSize,
  setPageRecords,
  pageIndex,
  gotoPage,
  setPageNumber,
  previousPage,
  nextPage,
  t,
}) => {
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  if (isDesktop) {
    return (
      <Row>
        <Col md="8">
          <span className="mx-2">
            {t("table.page")}{" "}
            <strong>
              {pageNumber + 1} {t("table.of")} {numberOfRecords}
            </strong>
          </span>
          <span className="ms-3 me-2">{t("table.show")}:</span>
          <Form.Select
            className="d-inline-block w-auto"
            value={pageRecords}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPageRecords(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageRecords) => (
              <option key={pageRecords} value={pageRecords}>
                {pageRecords}
              </option>
            ))}
          </Form.Select>

          <span className="ms-3 me-2">{t("table.goToPage")}:</span>
          <Form.Control
            className="d-inline-block"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "75px" }}
          />
        </Col>
        <Col md="4">
          <Pagination className="float-end">
            <Pagination.First
              onClick={() => {
                setPageNumber(0);
                gotoPage(0);
              }}
              disabled={pageNumber === 0}
            />
            <Pagination.Prev
              onClick={() => {
                setPageNumber(pageNumber - 1);
                previousPage();
              }}
              disabled={pageNumber <= 0}
            />
            <Pagination.Next
              onClick={() => {
                setPageNumber(pageNumber + 1);
                nextPage();
              }}
              disabled={pageNumber + 1 >= numberOfRecords}
            />
            <Pagination.Last
              onClick={() => {
                setPageNumber(numberOfRecords - 1);
                gotoPage(numberOfRecords - 1);
              }}
              disabled={pageNumber + 1 === numberOfRecords}
            />
          </Pagination>
        </Col>
      </Row>
    );
  } else {
    return (
      <React.Fragment>
        <div className="sim-pagination mt-3">
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Pagination className="float-end">
                <Pagination.Prev
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                    previousPage();
                  }}
                  disabled={pageNumber <= 0}
                />
                <Col className="d-flex align-items-end mx-2 px-3">
                  <span className="mx-2">
                    <strong>
                      {pageIndex + 1} {t("table.of")} {numberOfRecords}
                    </strong>
                  </span>
                </Col>
                <Pagination.Next
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                    nextPage();
                  }}
                  disabled={pageNumber + 1 >= numberOfRecords}
                />
              </Pagination>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <span className="me-2">{t("table.show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageRecords}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  setPageRecords(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageRecords) => (
                  <option key={pageRecords} value={pageRecords}>
                    {pageRecords}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <div className="float-end">
                <span className="me-2">{t("table.goToPage")}:</span>
                <Form.Control
                  className="d-inline-block"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "75px" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
};

export default SimPagination;
