import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Table, Form, Offcanvas } from "react-bootstrap";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { resumeSims } from "../../../../redux/slices/sims";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "../../../../components/spinners/Loader";
import NotyfContext from "../../../../contexts/NotyfContext";
import useWindowSize from "../../../../hooks/useWindowSize";

const ResumeSimModal = ({
  sims,
  openResumeModal,
  setOpenResumeModal,
  clearSelectedRows,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const notyf = useContext(NotyfContext);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(openResumeModal);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (openResumeModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [openResumeModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setOpenResumeModal(false);
    }, 300);
  };

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const initialValues = {
    sims: sims,
  };

  const validation = Yup.object().shape({
    sims: Yup.array().required("Please select at least one SIM"),
  });

  if (isDesktop) {
    return (
      <Modal
        show={openResumeModal}
        onHide={() => setOpenResumeModal(false)}
        centered
        size="lg"
        backdrop="static"
        scrollable
      >
        <Modal.Header>Resume SIMs</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues, setStatus }) => {
              const data = {
                sims: values.sims.map((sim) => sim.imsi),
              };
              await dispatch(resumeSims(data.sims))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "SIMs resumed successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Success");
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Error");
                })
                .finally(() => {
                  setCurrentStep(3);
                  setValues(initialValues);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              status,
              validateForm,
              setFieldTouched,
            }) => {
              const nextStep = () => {
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    if (currentStep === 1) {
                      setCurrentStep(2);
                      handleSubmit();
                    }
                  } else {
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              };

              return (
                <Form onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>IMSI</th>
                          <th>ICCID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.sims.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.imsi}</td>
                              <td>{item.iccid}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                  {currentStep === 2 && <Loader />}
                  {currentStep === 3 && status === "Success" && (
                    <div className="text-center">
                      <FaCheckCircle
                        size={120}
                        className="mt-3"
                        color="#4BBF73"
                      />
                      <h3 className="mt-3">Order Complete</h3>
                    </div>
                  )}
                  {currentStep === 3 && status === "Error" && (
                    <div className="text-center">
                      <FaExclamationCircle
                        size={120}
                        className="mt-3"
                        color="#FF9494"
                      />
                      <h3 className="mt-3">Order Error</h3>
                      <p>Please contact the administrator.</p>
                    </div>
                  )}
                  <Modal.Footer>
                    {currentStep === 1 && (
                      <React.Fragment>
                        <Button
                          variant="primary"
                          onClick={() => setOpenResumeModal(false)}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={nextStep}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                          }}
                        >
                          Resume
                        </Button>
                      </React.Fragment>
                    )}
                    {currentStep === 3 && (
                      <Button
                        onClick={() => {
                          setCurrentStep(1);
                          setOpenResumeModal(false);
                          clearSelectedRows();
                        }}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Close
                      </Button>
                    )}
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        onHide={handleHide}
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>Resume SIMs</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues, setStatus }) => {
              const data = {
                sims: values.sims.map((sim) => sim.imsi),
              };
              await dispatch(resumeSims(data.sims))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "SIMs resumed successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Success");
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Error");
                })
                .finally(() => {
                  setCurrentStep(3);
                  setValues(initialValues);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              status,
              validateForm,
              setFieldTouched,
            }) => {
              const nextStep = () => {
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    if (currentStep === 1) {
                      setCurrentStep(2);
                      handleSubmit();
                    }
                  } else {
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              };

              return (
                <Form onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>IMSI</th>
                          <th>ICCID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.sims.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.imsi}</td>
                              <td>{item.iccid}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                  {currentStep === 2 && <Loader />}
                  {currentStep === 3 && status === "Success" && (
                    <div className="text-center">
                      <FaCheckCircle
                        size={80}
                        className="mt-3"
                        color="#4BBF73"
                      />
                      <h3 className="mt-3">Order Complete</h3>
                    </div>
                  )}
                  {currentStep === 3 && status === "Error" && (
                    <div className="text-center">
                      <FaExclamationCircle
                        size={80}
                        className="mt-3"
                        color="#FF9494"
                      />
                      <h3 className="mt-3">Order Error</h3>
                      <p>Please contact the administrator.</p>
                    </div>
                  )}
                  <div className="d-flex flex-column align-items-center mt-3">
                    {currentStep === 1 && (
                      <React.Fragment>
                        <Button
                          variant="primary"
                          onClick={nextStep}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                            padding: "10px",
                            width: "100%",
                          }}
                          className="mb-3"
                        >
                          Resume
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleHide}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                            padding: "10px",
                            width: "100%",
                          }}
                        >
                          Cancel
                        </Button>
                      </React.Fragment>
                    )}
                    {currentStep === 3 && (
                      <Button
                        onClick={() => {
                          setCurrentStep(1);
                          handleHide();
                          clearSelectedRows();
                        }}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        Close
                      </Button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default ResumeSimModal;
