import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Image, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import SignIn from "../../components/auth/SignIn";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";
import Clicksims from "../../assets/img/clicksims.png";
import { useWhitelabel } from "../../contexts/WhitelabelContext";

const SignInPage = () => {
  const dispatch = useDispatch();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const currentUrl = window.location.hostname;
  const [isLoading, setIsLoading] = useState(true);
  const {
    whitelabelLoading,
    error,
    whitelabelUrl,
    faviconUrl,
    backgroundColor,
    fontColor,
  } = useWhitelabel();
  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl)).finally(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  }, []);

  if (isLoading || whitelabelLoading) {
    return (
      <React.Fragment>
        <Helmet title="Sign In">
          <link rel="icon" href={faviconUrl} />
        </Helmet>
        <Card style={{ minHeight: "490px" }}>
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <Spinner
                animation="border"
                variant="primary"
                role="status"
                style={{ "--dynamic-spinner-color": "#00acdc" }}
              />
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Sign In">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <Image
                src={whitelabelUrl}
                alt=""
                className="img-fluid"
                width="150"
                height="150"
              />
            </div>
            <SignIn />
          </div>
          <div className="mt-4 pt-4 border-top d-flex justify-content-end align-items-center">
            <p
              className="text-muted mb-0 me-2"
              style={{
                fontSize: "12px",
                fontWeight: "600",
                fontFamily: "Roboto, sans-serif",
                fontStyle: "italic",
              }}
            >
              Powered by
            </p>
            <Image src={Clicksims} alt="Powered by Logo" height="65" />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
