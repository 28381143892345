import React, { useContext } from "react";
import {
  Card,
  Row,
  Button,
  Table,
  Badge,
  Col,
  OverlayTrigger,
  Tooltip,
  DropdownButton,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import SimsMap from "../SimsMap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import SimDataSessionsModal from "./SimDataSessionsModal";
import SmsUsageModal from "../modals/SmsUsageModal";
import UpdateLocationModal from "../modals/UpdateLocationModal";
import ImeiLockModal from "../modals/ImeiLockModal";
import HlrProfileModal from "../../../hlr/HlrProfileModal";
import { useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import { BiInfoCircle } from "react-icons/bi";
import NotyfContext from "../../../../contexts/NotyfContext";

const Single = ({ sim, cellInfo, network }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [activityRecords, setActivityRecords] = useState([]);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showUpdateLocationModal, setShowUpdateLocationModal] = useState(false);
  const [showImeiLockModal, setShowImeiLockModal] = useState(false);
  const [showHlrProfileModal, setShowHlrProfileModal] = useState(false);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const notyf = useContext(NotyfContext);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You lack necessary permissions, please contact your parent account owner.
    </Tooltip>
  );

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    if (sim && sim.simActivity && sim.simActivity.length > 0) {
      const newActivityRecords = [...sim.simActivity].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivityRecords(newActivityRecords);
    }
  }, [sim]);

  return (
    <Card>
      <Card.Body>
        {isDesktop && (
          <>
            <Row className="g-0 mb-2">
              <strong>{t("simProfile.actions")}</strong>
            </Row>
            <Row className="mb-3">
              <Col>
                <Button
                  className="me-2 mb-1 px-2"
                  id="simsHLR"
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                  onClick={() => setShowHlrProfileModal(true)}
                >
                  <span>{t("simProfile.hlr")}</span>
                </Button>
                <Button
                  variant="primary"
                  className="me-2 mb-1 px-2"
                  id="simsHLRProfile"
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                  onClick={() => setShowSessionModal(true)}
                >
                  <span>{t("simProfile.session")}</span>
                </Button>

                <Button
                  variant="success"
                  className="me-2 mb-1 px-2"
                  id="simsSMSUsage"
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                  onClick={() => setShowSmsModal(true)}
                >
                  <span>{t("simProfile.smsUsage")}</span>
                </Button>

                {(sim.status === "Active" || sim.status === "Productive") && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      activeUser.role === "subaccount" &&
                      !activeUser.privileges.some(
                        (privilege) => privilege.name === "UpdateLocation"
                      ) ? (
                        renderTooltip
                      ) : (
                        <></>
                      )
                    }
                  >
                    <span className="d-inline-block">
                      <Button
                        variant="warning"
                        className="me-2 mb-1 px-2"
                        id="simsUpdateLoc"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                        onClick={() => {
                          setShowUpdateLocationModal(true);
                        }}
                        disabled={
                          activeUser.role === "subaccount" &&
                          !activeUser.privileges.some(
                            (privilege) => privilege.name === "UpdateLocation"
                          )
                        }
                      >
                        <span>{t("simProfile.updateLoc")}</span>
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}

                {sim.status === "Productive" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      activeUser.role === "subaccount" &&
                      !activeUser.privileges.some(
                        (privilege) => privilege.name === "IMEILock"
                      ) ? (
                        renderTooltip
                      ) : (
                        <></>
                      )
                    }
                  >
                    <span className="d-inline-block">
                      <Button
                        variant="info"
                        className="me-2 mb-1 px-2"
                        id="simsActionTest"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                        onClick={() => setShowImeiLockModal(true)}
                        disabled={
                          activeUser.role === "subaccount" &&
                          !activeUser.privileges.some(
                            (privilege) => privilege.name === "IMEILock"
                          )
                        }
                      >
                        {t("simProfile.imeiLock")}
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}
              </Col>
            </Row>
          </>
        )}
        {!isDesktop && (
          <DropdownButton
            as={ButtonGroup}
            title={t("simProfile.actions")}
            variant="primary"
            style={{
              borderRadius: "20px",
              border: "none",
              fontSize: "16px",
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              width: "100%",
              padding: "10px",
            }}
          >
            <Dropdown.Item
              eventKey="1"
              onClick={() => setShowHlrProfileModal(true)}
            >
              {t("simProfile.hlr")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => setShowSessionModal(true)}
            >
              {t("simProfile.session")}
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={() => setShowSmsModal(true)}>
              {t("simProfile.smsUsage")}
            </Dropdown.Item>
            {(sim.status === "Active" || sim.status === "Productive") && (
              <Dropdown.Item
                as="div"
                onClick={() => setShowUpdateLocationModal(true)}
                // disabled={
                //   activeUser.role === "subaccount" &&
                //   !activeUser.privileges.some(
                //     (privilege) => privilege.name === "UpdateLocation"
                //   )
                // }
                style={{
                  position: "relative",
                  cursor: "not-allowed",
                  pointerEvents: "none",
                }}
              >
                <span className="d-inline-block">
                  {t("simProfile.updateLoc")}
                  {activeUser.role === "subaccount" &&
                    !activeUser.privileges.some(
                      (privilege) => privilege.name === "UpdateLocation"
                    ) && (
                      <BiInfoCircle
                        className="ms-2"
                        size={22}
                        onClick={(e) => {
                          e.stopPropagation();
                          notyf.open({
                            type: "danger",
                            duration: 5000,
                            message:
                              "You lack necessary permissions, please contact your parent account owner.",
                            ripple: true,
                            dismissible: false,
                            position: {
                              x: "center",
                              y: "top",
                            },
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                      />
                    )}
                </span>
              </Dropdown.Item>
            )}
            {(sim.status === "Active" || sim.status === "Productive") && (
              <Dropdown.Item
                as="div"
                onClick={() => setShowImeiLockModal(true)}
                // disabled={
                //   activeUser.role === "subaccount" &&
                //   !activeUser.privileges.some(
                //     (privilege) => privilege.name === "UpdateLocation"
                //   )
                // }
                style={{
                  position: "relative",
                  cursor: "not-allowed",
                  pointerEvents: "none",
                }}
              >
                <span className="d-inline-block">
                  {t("simProfile.imeiLock")}
                  {activeUser.role === "subaccount" &&
                    !activeUser.privileges.some(
                      (privilege) => privilege.name === "IMEILock"
                    ) && (
                      <BiInfoCircle
                        className="ms-2"
                        size={22}
                        onClick={(e) => {
                          e.stopPropagation();
                          notyf.open({
                            type: "danger",
                            duration: 5000,
                            message:
                              "You lack necessary permissions, please contact your parent account owner.",
                            ripple: true,
                            dismissible: false,
                            position: {
                              x: "center",
                              y: "top",
                            },
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                      />
                    )}
                </span>
              </Dropdown.Item>
            )}
          </DropdownButton>
        )}

        <HlrProfileModal
          showHlrProfileModal={showHlrProfileModal}
          setShowHlrProfileModal={setShowHlrProfileModal}
          sim={sim}
        />
        <SimDataSessionsModal
          id={sim.id}
          showSessionModal={showSessionModal}
          setShowSessionModal={setShowSessionModal}
        />
        <SmsUsageModal
          imsi={sim.imsi}
          showSmsModal={showSmsModal}
          setShowSmsModal={setShowSmsModal}
        />
        <UpdateLocationModal
          showUpdateLocationModal={showUpdateLocationModal}
          setShowUpdateLocationModal={setShowUpdateLocationModal}
          sim={sim}
        />
        <ImeiLockModal
          sim={sim}
          showImeiLockModal={showImeiLockModal}
          setShowImeiLockModal={setShowImeiLockModal}
        />
        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>{t("simProfile.usage")}</th>
              <td>
                {sim.totalBytes !== null
                  ? `${parseFloat(sim.totalMBytes).toFixed(3)}MB`
                  : "0.00MB"}
              </td>
            </tr>
            <tr>
              <th>{t("simProfile.imei")}</th>
              <td>{sim.imei}</td>
            </tr>
            <tr>
              <th>{t("simProfile.dataSession")}</th>
              <td>
                <div
                  className={`circle ${
                    sim.status === "Active"
                      ? "pulse green"
                      : sim.status === "Productive" ||
                        sim.status === "TestProductive"
                      ? "blue"
                      : sim.status === "Suspended"
                      ? "yellow"
                      : sim.status === "OnStock"
                      ? "grey"
                      : sim.status === "Deleted"
                      ? "red"
                      : null
                  }`}
                ></div>
              </td>
            </tr>
            <tr>
              <th>{t("simProfile.status")}</th>
              <td>
                <Badge
                  bg={
                    sim.status === "Productive"
                      ? "primary"
                      : sim.status === "Active"
                      ? "success"
                      : sim.status === "Suspended"
                      ? "warning"
                      : sim.status === "Terminated"
                      ? "danger"
                      : sim.status === "OnStock"
                      ? "light"
                      : sim.status === "Deleted"
                      ? "danger"
                      : sim.status === "TestProductive"
                      ? "primary"
                      : sim.status === "AutoSuspended"
                      ? "warning"
                      : null
                  }
                >
                  {t(sim.status)}
                </Badge>
              </td>
            </tr>
          </tbody>
        </Table>

        <hr />
        <strong>{t("simProfile.map")}</strong>
        <SimsMap
          className="my-3"
          lat={parseFloat(cellInfo.latitude)}
          lon={parseFloat(cellInfo.longitude)}
        />
        <Table size="sm">
          <tbody>
            <tr>
              <th>{t("simProfile.provider")}</th>
              <td>{sim.sku}</td>
            </tr>
            <tr>
              <th>{t("simProfile.iccid")}</th>
              <td>{sim.iccid}</td>
            </tr>
            <tr>
              <th>{t("simProfile.msisdn")}</th>
              <td>{sim.msisdn}</td>
            </tr>
            <tr>
              <th>{t("simProfile.imsi")}</th>
              <td>{sim.imsi}</td>
            </tr>
            <tr>
              <th>{t("simProfile.country")}</th>
              <td>{network?.country ? network?.country : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.operator")}</th>
              <td>{network?.network ? network?.network : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.area")}</th>
              <td>{cellInfo.area ? cellInfo.area : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.cell")}</th>
              <td>{cellInfo.cell ? cellInfo.cell : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.longitude")}</th>
              <td>{cellInfo.longitude ? cellInfo.longitude : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.latitude")}</th>
              <td>{cellInfo.latitude ? cellInfo.latitude : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.cellRange")}</th>
              <td>{cellInfo.range ? cellInfo.range : "N/A"}</td>
            </tr>
          </tbody>
        </Table>
        <hr />

        <strong>{t("simProfile.activity")}</strong>

        <ul className="timeline mt-2">
          {activityRecords.slice(0, 5).map((activityElement, index) => (
            <React.Fragment key={index}>
              {activityElement.type === "ACTIVATE" && (
                <li className="timeline-item">
                  <strong>Activated</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "SUSPEND" && (
                <li className="timeline-item">
                  <strong>Suspended</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "RESUME" && (
                <li className="timeline-item">
                  <strong>Resumed</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "MODIFY" && (
                <li className="timeline-item">
                  <strong>Modified</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "TERMINATE" && (
                <li className="timeline-item">
                  <strong>Terminated</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default Single;
