import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Collapse, Form, Modal, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { enableWhitelabeling } from "../../../redux/slices/whitelabel";
import NotyfContext from "../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";

const ToggleWhitelabelingModal = ({
  showToggleWhitelabelingModal,
  setShowToggleWhitelabelingModal,
  entityUser,
}) => {
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(
    showToggleWhitelabelingModal
  );
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (showToggleWhitelabelingModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showToggleWhitelabelingModal]);

  const handleHide = () => {
    setFadeOut(true); // Trigger fade-out animation
    setTimeout(() => {
      setInternalShow(false); // Unmount after animation completes
      setShowToggleWhitelabelingModal(false); // Notify parent
    }, 300); // Match the animation duration
  };

  const initialValues = {
    enableWhitelabeling: entityUser?.whitelabelingEnabled,
    price: entityUser.serverDetails?.price,
    url: entityUser.serverDetails?.url ?? "",
  };

  const validationSchema = Yup.object({
    enableWhitelabeling: Yup.boolean(),
    price: Yup.number().required("Price is required"),
    url: Yup.string().required("URL is required"),
  });

  if (isDesktop) {
    return (
      <Modal
        show={showToggleWhitelabelingModal}
        onHide={() => setShowToggleWhitelabelingModal(false)}
        centered
        backdrop="static"
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("crm.whitelabelModal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              await dispatch(
                enableWhitelabeling({
                  id: entityUser.id,
                  price: values.price,
                  url: values.url,
                  role: entityUser.privilege,
                })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Whitelabeling enabled successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowToggleWhitelabelingModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="enableWhitelabeling"
                      label={t("crm.whitelabelModal.enableWhitelabel")}
                      checked={values.enableWhitelabeling}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                      }}
                    />
                  </Form.Group>
                  <Collapse
                    in={!!values.enableWhitelabeling}
                    dimension="height"
                  >
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("crm.whitelabelModal.pricePerMonth")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="price"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.price && errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("crm.whitelabelModal.url")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="url"
                          value={values.url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.url && errors.url}
                        />
                        {!!touched.url && (
                          <Form.Control.Feedback type="invalid">
                            {errors.url}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </div>
                  </Collapse>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                      onClick={() => setShowToggleWhitelabelingModal(false)}
                    >
                      {t("crm.whitelabelModal.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                    >
                      {t("crm.whitelabelModal.submit")}
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        backdrop="static"
        scrollable={true}
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>{t("crm.whitelabelModal.title")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              await dispatch(
                enableWhitelabeling({
                  id: entityUser.id,
                  price: values.price,
                  url: values.url,
                  role: entityUser.privilege,
                })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Whitelabeling enabled successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowToggleWhitelabelingModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="enableWhitelabeling"
                      label={t("crm.whitelabelModal.enableWhitelabel")}
                      checked={values.enableWhitelabeling}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                      }}
                    />
                  </Form.Group>
                  <Collapse
                    in={!!values.enableWhitelabeling}
                    dimension="height"
                  >
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("crm.whitelabelModal.pricePerMonth")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="price"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.price && errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("crm.whitelabelModal.url")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="url"
                          value={values.url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.url && errors.url}
                        />
                        {!!touched.url && (
                          <Form.Control.Feedback type="invalid">
                            {errors.url}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </div>
                  </Collapse>
                  <div className="d-flex flex-column justify-content-center mt-3">
                    <Button
                      variant="primary"
                      type="submit"
                      className="mb-3"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                        padding: "10px",
                      }}
                    >
                      {t("crm.whitelabelModal.submit")}
                    </Button>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                        padding: "10px",
                      }}
                      onClick={() => setShowToggleWhitelabelingModal(false)}
                    >
                      {t("crm.whitelabelModal.cancel")}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default ToggleWhitelabelingModal;
