import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";

import Main from "../components/Main";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelabel } from "../redux/slices/whitelabel";

const Auth = ({ children }) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const currentUrl = window.location.hostname;
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, [location]);

  return (
    <React.Fragment>
      <div
        className="authMain main d-flex w-100 justify-content-center"
        style={{ background: whitelabel?.backgroundColor ?? "#00acdc" }}
      >
        <Container className="d-flex flex-column">
          <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                {children}
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Settings /> */}
    </React.Fragment>
  );
};

export default Auth;
