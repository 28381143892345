import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { createEmail } from "../../../redux/slices/distribution";
import NotyfContext from "../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";

const CreateEmailModal = ({
  showCreateEmailModal,
  setShowCreateEmailModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(showCreateEmailModal);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (showCreateEmailModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showCreateEmailModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShowCreateEmailModal(false);
    }, 300);
  };

  const initialValues = {
    category: "",
    name: "",
    address: "",
  };

  const validation = Yup.object().shape({
    category: Yup.string().required("Category is required"),
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
  });

  if (isDesktop) {
    return (
      <Modal
        show={showCreateEmailModal}
        onHide={() => setShowCreateEmailModal(false)}
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("emails.newEmail")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              await dispatch(createEmail({ data: values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Email created successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setValues(initialValues);
                  setShowCreateEmailModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              setValues,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("emails.category")}</Form.Label>
                    <Form.Select
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.category && errors.category)}
                    >
                      <option value="">{t("emails.selectCategory")}</option>
                      <option value="Invoice">{t("emails.invoice")}</option>
                      <option value="Watchdog">{t("emails.watchdog")}</option>
                    </Form.Select>
                    {!!touched.category && (
                      <Form.Control.Feedback type="invalid">
                        {errors.category}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("emails.name")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.name && errors.name)}
                    />
                    {!!touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("emails.address")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.address && errors.address)}
                    />
                    {!!touched.address && (
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                      onClick={() => {
                        setValues(initialValues);
                        setShowCreateEmailModal(false);
                      }}
                    >
                      {t("emails.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                    >
                      {t("emails.submit")}
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        backdrop="static"
        scrollable="true"
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>{t("emails.newEmail")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              await dispatch(createEmail({ data: values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Email created successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setValues(initialValues);
                  setShowCreateEmailModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              setValues,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("emails.category")}</Form.Label>
                    <Form.Select
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.category && errors.category)}
                    >
                      <option value="">{t("emails.selectCategory")}</option>
                      <option value="Invoice">{t("emails.invoice")}</option>
                      <option value="Watchdog">{t("emails.watchdog")}</option>
                    </Form.Select>
                    {!!touched.category && (
                      <Form.Control.Feedback type="invalid">
                        {errors.category}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("emails.name")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.name && errors.name)}
                    />
                    {!!touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("emails.address")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.address && errors.address)}
                    />
                    {!!touched.address && (
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                        padding: "10px",
                        width: "100%",
                      }}
                      className="mb-3"
                    >
                      {t("emails.submit")}
                    </Button>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                        padding: "10px",
                        width: "100%",
                      }}
                      onClick={() => {
                        setValues(initialValues);
                        handleHide();
                      }}
                    >
                      {t("emails.cancel")}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default CreateEmailModal;
