import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Table, Offcanvas } from "react-bootstrap";
import { getSims, terminateSims } from "../../../../redux/slices/sims";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { Formik } from "formik";
import * as Yup from "yup";
import NotyfContext from "../../../../contexts/NotyfContext";
import Loader from "../../../../components/spinners/Loader";
import useWindowSize from "../../../../hooks/useWindowSize";

const TerminateSimModal = ({
  sims,
  openTerminateModal,
  setOpenTerminateModal,
  clearSelectedRows,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const notyf = useContext(NotyfContext);
  const [internalShow, setInternalShow] = useState(openTerminateModal);
  const [fadeOut, setFadeOut] = useState(false);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  useEffect(() => {
    if (openTerminateModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [openTerminateModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setOpenTerminateModal(false);
    }, 300);
  };

  const initialValues = {
    targetState: "",
    sims: sims,
  };

  const validation = Yup.object().shape({
    targetState: Yup.string().required("Required"),
    sims: Yup.array().required("Required"),
  });

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  if (isDesktop) {
    return (
      <Modal
        show={openTerminateModal}
        onHide={() => {
          setOpenTerminateModal(false);
          setCurrentStep(1);
        }}
        backdrop="static"
        centered
        size="lg"
        scrollable
      >
        <Modal.Header>
          <Modal.Title>Terminate SIMs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues, setStatus }) => {
              await dispatch(
                terminateSims({
                  sims: values.sims.map((sim) => sim.imsi),
                  targetState: values.targetState,
                })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "SIMs terminated successfully.",
                    dismissible: true,
                    ripple: true,
                    duration: 5000,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Success");
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    dismissible: true,
                    ripple: true,
                    duration: 5000,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Error");
                })
                .finally(() => {
                  setCurrentStep(3);
                  setValues(initialValues);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              validateForm,
              status,
              setValues,
            }) => {
              const nextStep = () => {
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    if (currentStep === 1) {
                      setCurrentStep(2);
                      handleSubmit();
                    }
                  } else {
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              };
              return (
                <Form onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <React.Fragment>
                      <Form.Group className="mb-3">
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th>IMSI</th>
                              <th>ICCID</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.sims.map((sim) => (
                              <tr key={sim.imsi}>
                                <td>{sim.imsi}</td>
                                <td>{sim.iccid}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Target State</Form.Label>
                        <Form.Select
                          name="targetState"
                          value={values.targetState}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.targetState && errors.targetState}
                        >
                          <option value="">Select Target State</option>
                          <option value="OnStock">On Stock</option>
                          <option value="Deleted">Terminated</option>
                        </Form.Select>
                        {touched.targetState && errors.targetState && (
                          <Form.Control.Feedback type="invalid">
                            {errors.targetState}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </React.Fragment>
                  )}
                  {currentStep === 2 && <Loader />}
                  {currentStep === 3 && status === "Success" && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <FaCheckCircle size={120} color="#4BBF73" />
                      <h3>Success</h3>
                    </div>
                  )}
                  {currentStep === 3 && status === "Error" && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <FaExclamationCircle size={120} color="#FF0000" />
                      <h3 className="mt-3">Order Error</h3>
                      <p>Please contact the administrator.</p>
                    </div>
                  )}
                  <Modal.Footer>
                    {currentStep === 1 && (
                      <React.Fragment>
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                          }}
                          onClick={() => {
                            setCurrentStep(1);
                            setValues(initialValues);
                            setOpenTerminateModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                          }}
                          onClick={nextStep}
                        >
                          Submit
                        </Button>
                      </React.Fragment>
                    )}
                    {currentStep === 3 && (
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                        onClick={() => {
                          setCurrentStep(1);
                          setValues(initialValues);
                          setOpenTerminateModal(false);
                          clearSelectedRows();
                        }}
                      >
                        Close
                      </Button>
                    )}
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        placement="bottom"
        show={internalShow}
        onHide={handleHide}
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>Terminate SIMs</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues, setStatus }) => {
              await dispatch(
                terminateSims({
                  sims: values.sims.map((sim) => sim.imsi),
                  targetState: values.targetState,
                })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "SIMs terminated successfully.",
                    dismissible: true,
                    ripple: true,
                    duration: 5000,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Success");
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    dismissible: true,
                    ripple: true,
                    duration: 5000,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                  setStatus("Error");
                })
                .finally(() => {
                  setCurrentStep(3);
                  setValues(initialValues);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              validateForm,
              status,
              setValues,
            }) => {
              const nextStep = () => {
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    if (currentStep === 1) {
                      setCurrentStep(2);
                      handleSubmit();
                    }
                  } else {
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              };
              return (
                <Form onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <React.Fragment>
                      <Form.Group className="mb-3">
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th>IMSI</th>
                              <th>ICCID</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.sims.map((sim) => (
                              <tr key={sim.imsi}>
                                <td>{sim.imsi}</td>
                                <td>{sim.iccid}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Target State</Form.Label>
                        <Form.Select
                          name="targetState"
                          value={values.targetState}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.targetState && errors.targetState}
                        >
                          <option value="">Select Target State</option>
                          <option value="OnStock">On Stock</option>
                          <option value="Deleted">Terminated</option>
                        </Form.Select>
                        {touched.targetState && errors.targetState && (
                          <Form.Control.Feedback type="invalid">
                            {errors.targetState}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </React.Fragment>
                  )}
                  {currentStep === 2 && <Loader />}
                  {currentStep === 3 && status === "Success" && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <FaCheckCircle size={120} color="#4BBF73" />
                      <h3>Success</h3>
                    </div>
                  )}
                  {currentStep === 3 && status === "Error" && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <FaExclamationCircle size={120} color="#FF0000" />
                      <h3 className="mt-3">Order Error</h3>
                      <p>Please contact the administrator.</p>
                    </div>
                  )}
                  <div className="d-flex flex-column align-items-center mt-3">
                    {currentStep === 1 && (
                      <React.Fragment>
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                            padding: "10px",
                            width: "100%",
                          }}
                          className="mb-3"
                          onClick={nextStep}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#ffffff",
                            border: "none",
                            padding: "10px",
                            width: "100%",
                          }}
                          onClick={() => {
                            setCurrentStep(1);
                            setValues(initialValues);
                            handleHide();
                          }}
                        >
                          Cancel
                        </Button>
                      </React.Fragment>
                    )}
                    {currentStep === 3 && (
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                          padding: "10px",
                          width: "100%",
                        }}
                        onClick={() => {
                          setCurrentStep(1);
                          setValues(initialValues);
                          handleHide();
                          clearSelectedRows();
                        }}
                      >
                        Close
                      </Button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default TerminateSimModal;
